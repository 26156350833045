import { LandingPageStore, LandingPageStoreActions, LandingPageStoreObservables } from './types';

export const selectLandingPage = (state: LandingPageStoreObservables) => {
  return state?.contentfulData || {};
};

export const selectEnablePersonalizeSegmentFlag = (state: LandingPageStoreObservables) => {
  return state?.enablePersonalizeSegmentFlag || false;
};

export const selectPromotions = (state: LandingPageStoreObservables) => state.promotions;

export const actions = (
  state: LandingPageStore,
): Pick<LandingPageStore, keyof LandingPageStoreActions> => {
  return {
    setContentfulPosts: state.setContentfulPosts,
    updateFetchedExternalData: state.updateFetchedExternalData,
    updateMediaExternalSource: state.updateMediaExternalSource,
    removePostById: state.removePostById,
    setPromotions: state.setPromotions,
  };
};
