import { ZDTTwilioSegment } from '@zalora/doraemon-ts';
import { getTwilioSegment } from 'api/twiliosegment';
import { getEnvConfiguration } from 'utils/env-configuration';
import { captureError } from 'utils/raven';

const USER_TRAIT_STORAGE_KEY = 'userTrait';
const USER_TRAIT_EXPIRY_TIME = 12 * 60 * 60 * 1000; // 12 hours

/**
 * Gets Audience Traits based on User details.
 *
 * @param userId A Zuid or an Anonymous ID.
 * @param isLogin The login state of the user.
 */
export const getUserTrait = async (userId: string, isLogin: boolean) => {
  const userTrait = getUserTraitFromStorage(userId);

  if (userTrait) {
    return userTrait;
  }

  const fetchData = await getTwilioSegment({
    params: { anonymous_id: isLogin ? '' : userId },
    allData: {},
    segmentSpaceID: getEnvConfiguration('SEGMENT_SPACE_ID') || '',
  });

  if (fetchData) {
    setUserTraitFromStorage(fetchData, userId);
  }

  return fetchData;
};

const setUserTraitFromStorage = (
  userTrait: ZDTTwilioSegment.ProfilesTraitsAudience['Traits'],
  zuid: string,
) => {
  const expiryTime = Date.now() + USER_TRAIT_EXPIRY_TIME;
  const data = JSON.stringify({ [zuid]: userTrait, expiryTime });

  try {
    localStorage.setItem(USER_TRAIT_STORAGE_KEY, data);
  } catch (error) {
    captureError("[userTrait] Can't set user trait to local storage", { error, tag: 'handler' });
  }
};

const getUserTraitFromStorage = (zuid: string) => {
  try {
    const userTraitString = localStorage.getItem(USER_TRAIT_STORAGE_KEY);

    if (!userTraitString) {
      return null;
    }

    const userTrait = JSON.parse(userTraitString);

    // remove expired user trait
    if (!userTrait?.expiryTime || userTrait.expiryTime < Date.now()) {
      localStorage.removeItem(USER_TRAIT_STORAGE_KEY);

      return null;
    }

    return userTrait[zuid];
  } catch (error) {
    return null;
  }
};
