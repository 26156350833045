import React, { FC, memo, useRef } from 'react';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';
import { JQUERY_SCRIPT_FILE } from 'utils/cms';
import { getStaticAsset } from 'utils/domains';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';

/**
 * Workaround solution for supporting old CMS
 */
interface Props {
  content: string;
  className?: string;
  stylesFile?: string;
  id: string;
}

const HtmlBlockWithDangerScript: FC<Props> = ({ content, className, stylesFile, id }) => {
  const styles = stylesFile ? getStaticAsset(stylesFile) : '';
  const containerRef = useRef<HTMLDivElement | null>(null);
  const countryCode = getEnvConfiguration('CC');

  useInvokeCallbackOnceWhen(() => {
    if (!content) {
      return;
    }

    const { current } = containerRef || {};

    const isIncludedJquery = content.includes('$(');

    const renderContent = (shouldValidateEmptyContent = true) => {
      if (isServer() || (shouldValidateEmptyContent && !content)) {
        return;
      }

      const documentFragment = document.createRange().createContextualFragment(content);

      if (documentFragment && current) {
        if (current.innerHTML.trim()) {
          current.innerHTML = '';
        }

        current.appendChild(documentFragment);
      }
    };

    if (!isIncludedJquery) {
      renderContent(false);
    }

    if (typeof (window as typeof window & { $: unknown }).$ === 'undefined') {
      const script = document.createElement('script');

      script.src = `https://static-${countryCode}.zacdn.com${JQUERY_SCRIPT_FILE}`;
      document.head.appendChild(script);

      script.onload = () => renderContent();
    } else {
      renderContent();
    }

    return () => {
      current && (current.innerHTML = '');
    };
  }, !!content);

  if (!content) {
    return null;
  }

  return (
    <div className={className}>
      {styles ? (
        <link
          rel="stylesheet"
          href={styles}
        />
      ) : null}
      <div
        ref={containerRef}
        id={id}
        className={'mtl box fct-text mbm block'}
      />
    </div>
  );
};

export default memo(HtmlBlockWithDangerScript);
