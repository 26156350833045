import Link from 'next/link';
import React, { PropsWithChildren } from 'react';
import { getProductUrlFromAbsoluteUrl, getProductUrlFromSlug } from 'utils/url';

interface Props extends PropsWithChildren {
  to: string;
  className?: string;
  onClick?: () => void;
  data?: {
    productURL?: string;
  };
}

const ProductLink: React.FC<Props> = ({ children, to, className, onClick, data }) => {
  const productUrl =
    getProductUrlFromAbsoluteUrl(data?.productURL || '') || getProductUrlFromSlug(to);

  if (!productUrl) {
    return (
      <div
        className={className}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      passHref
      href={productUrl}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default ProductLink;
