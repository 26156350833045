import { ModalDialogConfig, ModalDialogVariant } from '@zalora/zui';

const AddToBagModalConfig: ModalDialogConfig = {
  modalProps: {
    variant: ModalDialogVariant.REGULAR,
    shouldAllowHeightExpand: true,
  },
};

export default AddToBagModalConfig;
