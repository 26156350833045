import { AUDIENCE, CONTENT_TYPE, DEFAULT_QUERY_PARAMS } from 'constants/contentful';
import { getContentfulLocale } from 'services/contentful/helper/getContentfulLocale';
import { FetchParams, StaticPageReqData } from 'types/contentful/ContentfulType';
import { getLanguage } from 'utils/locale';
import { getAudienceByPlatform, isUsingMobileData } from 'utils/post-type/helper';
import { isTrueValue } from '../helper';

export const getSegmentContentTypeQuery = (
  segment: string | undefined | null,
  countryISO: string,
  locale: string,
  isMobile: boolean,
): FetchParams => {
  const contentfulLocale = getContentfulLocale(getLanguage(locale));

  return {
    locale: contentfulLocale,
    content_type: CONTENT_TYPE.SEGMENT,
    'fields.segment[in]': segment,
    'fields.venture[in]': countryISO,
    'fields.audience[in]': isMobile ? AUDIENCE.AUDIENCE_APP : AUDIENCE.AUDIENCE_WEB,
    ...DEFAULT_QUERY_PARAMS,
  };
};

export const getStaticPageContentTypeQuery = ({
  cmsKey,
  countryISO,
  locale,
  isMobile,
}: StaticPageReqData): FetchParams => {
  const contentfulLocale = getContentfulLocale(getLanguage(locale));

  return {
    locale: contentfulLocale,
    content_type: CONTENT_TYPE.STATIC_PAGE,
    'fields.cmsKey[in]': cmsKey,
    'fields.venture[in]': countryISO,
    'fields.audience[in]': getAudienceByPlatform(isMobile),
    ...DEFAULT_QUERY_PARAMS,
  };
};

export const getContentByEntryIdQuery = (
  entryId: string,
  locale: string,
  contentType: CONTENT_TYPE,
  isMobile: boolean,
): FetchParams => {
  const { include } = DEFAULT_QUERY_PARAMS;
  const contentfulLocale = getContentfulLocale(getLanguage(locale));

  const params = {
    include,
    content_type: contentType,
    'sys.id': entryId,
    locale: contentfulLocale,
  };

  if (contentType === CONTENT_TYPE.STATIC_PAGE) {
    const audience = getAudienceByPlatform(isMobile);

    return { ...params, 'fields.audience[in]': audience };
  }

  return {
    ...params,
    'fields.audience[in]': isMobile ? AUDIENCE.AUDIENCE_APP : AUDIENCE.AUDIENCE_WEB,
  };
};

export const createQueryParams = (
  query: {
    entryId?: string;
    previewTime?: string;
    setDevice?: string;
    segment?: string;
    isPreviewMode?: string;
    shouldDisableMediaTimers?: boolean;
  },
  locale?: string,
  isMobile = true,
) => {
  const {
    entryId = '',
    previewTime = '',
    setDevice = '',
    segment = '',
    isPreviewMode = '',
    shouldDisableMediaTimers = false,
  } = query;

  return {
    isPreviewMode: isTrueValue(isPreviewMode),
    entryId,
    previewTime,
    isMobile: setDevice ? isUsingMobileData((setDevice as string) || '') : isMobile,
    segment,
    locale: locale || 'en-SG',
    shouldDisableMediaTimers,
  };
};
