import { ToastContainer } from '@zalora/zui';
import clsx from 'clsx';
import { FC, memo } from 'react';
import { useDeviceType } from 'hooks/useDeviceType';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';

const ToastRenderer: FC = () => {
  const toastStack = useUiStore(uiSelectors.toastStack);
  const { isMobile } = useDeviceType();

  if (toastStack.length <= 0) {
    return null;
  }

  return (
    <ToastContainer
      className={clsx('z-important pb-8 tablet:pb-16')}
      positionX={isMobile ? 'center' : 'left'}
      positionY={'bottom'}
    >
      {toastStack}
    </ToastContainer>
  );
};

export default memo(ToastRenderer);
