import { FC, ReactElement, useEffect, useRef } from 'react';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from 'constants/layout';
import { ANIMATION_POST_TYPE_CONFIG } from 'constants/post-types';
import { useDeviceType } from 'hooks/useDeviceType';
import { PostStyles } from 'types/post-types/PostTypes';

type Props = {
  children?: ReactElement | null;
  animation?: string;
  className?: string;
  styles?: PostStyles;
};

const findAnimatedPost = (target: Element): NodeListOf<Element> | null => {
  const animatedPostList = target.querySelectorAll(
    `.${ANIMATION_POST_TYPE_CONFIG.ANIMATED_POST_CLASS}`,
  );

  return animatedPostList;
};

const AnimationWrapper: FC<Props> = ({ className, children, animation, styles, ...props }) => {
  const { isDesktop } = useDeviceType();
  const targetRef = useRef<null>(null);

  useEffect(() => {
    if (!animation) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const animatedPost = findAnimatedPost(entry?.target);

          animatedPost?.forEach((item) => {
            if (!item) {
              return;
            }

            if (entry.isIntersecting) {
              item.classList.add('active');
            } else {
              item.classList.remove('active');
            }
          });
        });
      },
      {
        threshold: ANIMATION_POST_TYPE_CONFIG.ANIMATION_OBSERVER_THRESHOLD as number,
        rootMargin: `-${isDesktop ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE} 0px 0px 0px`,
      },
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [animation, isDesktop, children]);

  return (
    <div
      ref={targetRef}
      className={className}
      style={styles}
      {...props}
    >
      {children}
    </div>
  );
};

export default AnimationWrapper;
