import { createClient, ContentfulClientApi } from 'contentful';
import {
  CLIENT_NOT_INITIALIZED,
  CLIENT_PARAMS_NOT_FOUND,
  CONTENTFUL_DELIVERY_HOST,
  CONTENTFUL_PREVIEW_HOST,
} from 'constants/contentful';
import { ClientParams, FetchParams, EntryResponse } from 'types/contentful/ContentfulType';
import { FetchStatus, PreviewParam } from 'types/post-types/PostTypes';
import { getEnvConfiguration } from 'utils/env-configuration';
import { captureError } from 'utils/raven';
import { ContentfulResponse, processSegmentPageResponse } from './helper';

let client: Nullable<ContentfulClientApi> = null;

interface Entity {
  sys: { id: string };
}

const createContentfulClient = (
  { host, spaceId, accessToken, contentfulEnv, removeUnresolved }: ClientParams,
  isRenew = false,
): Nullable<ContentfulClientApi> => {
  try {
    if (!host || !spaceId || !accessToken || !contentfulEnv) {
      throw CLIENT_PARAMS_NOT_FOUND;
    }

    const clientInit = createClient({
      host,
      accessToken,
      space: spaceId,
      environment: contentfulEnv,
      removeUnresolved,
      retryLimit: 0,
      logHandler: (error) =>
        captureError('Can not create CONTENTFUL client', { error, tag: 'handler' }),
    });

    if (!isRenew) {
      client = clientInit;
    }

    return clientInit;
  } catch (error) {
    captureError('Can not create CONTENTFUL client', { error, tag: 'handler' });

    return null;
  }
};

const getContentfulClient = (isPreview?: boolean) => {
  if (!client) {
    return initContentfulClient(isPreview);
  }

  return client;
};
const getClientParams = (isPreview?: boolean): ClientParams => {
  const environment = getEnvConfiguration('CONTENTFUL_ENVIRONMENT');
  const deliveryApiKey = getEnvConfiguration('CONTENTFUL_DELIVERY_API_ACCESS_TOKEN');
  const previewApiKey = getEnvConfiguration('CONTENTFUL_PREVIEW_API_ACCESS_TOKEN');
  const spaceId = getEnvConfiguration('CONTENTFUL_SPACE_ID');

  return {
    spaceId,
    accessToken: isPreview ? previewApiKey : deliveryApiKey,
    contentfulEnv: environment,
    host: isPreview ? CONTENTFUL_PREVIEW_HOST : CONTENTFUL_DELIVERY_HOST,
    removeUnresolved: true,
  };
};

const initContentfulClient = (
  isPreview?: boolean,
  isRenew?: boolean,
): Nullable<ContentfulClientApi> => {
  const clientParams = getClientParams(isPreview);

  return createContentfulClient({ ...clientParams }, isRenew);
};

const getEntriesByOptions = async (
  data: { query: FetchParams },
  { isPreviewMode, previewTime, shouldDisableMediaTimers }: PreviewParam,
  isMobile: boolean,
): Promise<EntryResponse> => {
  const query = data?.query || {};

  try {
    const contentful = getContentfulClient(isPreviewMode);

    if (!contentful) {
      throw CLIENT_NOT_INITIALIZED;
    }

    const { items } = await contentful.getEntries<ContentfulResponse['fields']>(query);

    if (!items?.length) {
      return { isSuccess: true, response: null, fetchStatus: FetchStatus.Loaded };
    }

    if (isPreviewMode) {
      //get all ids of posts and media
      const idStrings: string[] = [];
      const { posts = [] } = items?.[0]?.fields;

      posts.forEach((post) => {
        const { fields, sys } = post;
        const { media = [] } = fields || {};
        const { id } = sys;

        idStrings.push(id);

        if (media.length === 0) {
          return;
        }

        media.forEach((item) => {
          const { sys } = item;
          const { id } = sys || {};

          if (id) {
            idStrings.push(id);
          }
        });
      });

      const isPreview = false;
      const isRenew = true;
      const contentfulCDA = initContentfulClient(isPreview, isRenew);

      if (!contentfulCDA) {
        throw new Error('Can not create CDA');
      }

      const itemsAvailable = await contentfulCDA
        .getEntries({ 'sys.id[in]': idStrings.join(','), limit: 1000 })
        .then((response: { items: Entity[] }) => {
          const cdaEntries = response.items;

          return cdaEntries.reduce((acc: { [s: string]: boolean }, current: Entity) => {
            return { ...acc, [current.sys.id]: true };
          }, {});
        });

      const response = processSegmentPageResponse(
        items,
        {
          isPreviewMode,
          previewTime: previewTime || '',
          shouldDisableMediaTimers,
          itemsAvailable,
        },
        isMobile,
      );

      return {
        isSuccess: true,
        response,
        fetchStatus: FetchStatus.Loaded,
      };
    }

    return {
      isSuccess: true,
      response: processSegmentPageResponse(
        items,
        {
          isPreviewMode: false,
          previewTime: '',
          itemsAvailable: {},
          shouldDisableMediaTimers: false,
        },
        isMobile,
      ),
      fetchStatus: FetchStatus.Loaded,
    };
  } catch (error) {
    captureError('can not fetch entries from CONTENTFUL', {
      error,
      params: { query },
      tag: 'get-request',
    });

    return {
      isSuccess: false,
      error: 'can not fetch entries from CONTENTFUL',
      fetchStatus: FetchStatus.Failed,
    };
  }
};

export { getEntriesByOptions, initContentfulClient };
