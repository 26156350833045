import { BleedType } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import PriceDropSkeleton from 'components/price-drop/PriceDropSkeleton';
import {
  SkeletonBubble,
  SkeletonRecommendation,
  SkeletonBanner,
  SkeletonItem,
} from 'components/skeleton';
import { SkeletonGrid } from 'components/skeleton/SkeletonGrid';
import { CONTENT_TYPE } from 'constants/contentful';
import useUser from 'hooks/api/useUser';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelectors from 'stores/optimizely/selectors';
import { RawPostType } from 'types/post-types/PostTypes';
import { getRatioPost } from 'utils/post-type/helper';

export const PostTypeSkeleton: FC<{ post: RawPostType }> = ({ post }) => {
  const isPriceDropEnabled = useOptimizelyStore(optimizelySelectors.isPriceDropEnabled);
  const { data: user } = useUser();

  let Component: ReactNode = null;

  switch (post.contentType) {
    case CONTENT_TYPE.BUBBLE: {
      Component = (
        <div className="m-auto mb-4 desktop:mb-6">
          <SkeletonBubble />
        </div>
      );

      break;
    }

    case CONTENT_TYPE.TEXT_BANNER: {
      Component = (
        <div className="m-auto mb-4 flex desktop:mb-6">
          <SkeletonItem width="w-1/2 mx-auto pt-8 mx-4" />
          <SkeletonItem width="w-1/2 mx-auto pt-8 mx-4" />
        </div>
      );

      break;
    }

    case CONTENT_TYPE.BANNER: {
      Component = (
        <div className="m-auto mb-4 desktop:mb-6">
          <SkeletonBanner
            column={+(post.horizontalScrollScale || post.horizontalScrollScaleDesktop || 1)}
            ratio={getRatioPost(post.media)}
            className="mb-6 tablet:mb-8 desktop:mb-12"
            hasTitle={!!post.title}
          />
        </div>
      );

      break;
    }

    case CONTENT_TYPE.RECOMMENDATION: {
      Component = (
        <div className="m-auto mb-4 desktop:mb-6">
          <SkeletonRecommendation />
        </div>
      );

      break;
    }

    case CONTENT_TYPE.GRID: {
      Component = (
        <div className="m-auto mb-4 desktop:mb-6">
          <SkeletonGrid post={post} />
        </div>
      );

      break;
    }

    default: {
      break;
    }
  }

  if (isPriceDropEnabled && post.abtestFlagName === 'price_drop_tracker' && !!user) {
    return (
      <>
        {Component}
        <PriceDropSkeleton />
      </>
    );
  }

  return Component;
};

export const HomeSkeletonContent: FC<{ posts?: RawPostType[] }> = ({ posts }) => {
  const contentfulPosts = posts || [];

  return (
    <div className="mx-auto max-w-full animate-pulse overflow-x-hidden tablet:px-4 tablet:pb-6 desktop:max-w-screen-xl">
      {contentfulPosts.map((post: RawPostType) => {
        const { bleedType, entryId } = post;

        return (
          <div
            key={entryId}
            className={clsx('mx-auto', {
              'max-w-5xl px-4': bleedType === BleedType.Half,
              'max-w-full px-1': bleedType === BleedType.Full,
            })}
          >
            <PostTypeSkeleton post={post} />
          </div>
        );
      })}
    </div>
  );
};
