import { getEnvConfiguration } from 'utils/env-configuration';
import { captureError } from 'utils/raven';

type ContentfulGraphQLResponse<T> = {
  data: T;
};

const executeGraphQLQuery = async <T>(
  query: string,
  priority: 'high' | 'low' = 'high',
): Promise<T | undefined> => {
  const BASE_URL = 'https://graphql.contentful.com';

  const environment = getEnvConfiguration('CONTENTFUL_ENVIRONMENT');
  const deliveryApiKey = getEnvConfiguration('CONTENTFUL_DELIVERY_API_ACCESS_TOKEN');
  const spaceId = getEnvConfiguration('CONTENTFUL_SPACE_ID');

  if (!environment || !deliveryApiKey || !spaceId) {
    captureError('Either some or all required environment vars were not set up for Contentful.', {
      tag: 'handler',
    });

    return undefined;
  }

  try {
    const response = await fetch(
      `${BASE_URL}/content/v1/spaces/${spaceId}/environments/${environment}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${deliveryApiKey}`,
        },
        // browsers already supported `priority` in fetch API but TypeScript doesn't have it yet
        // https://developer.mozilla.org/en-US/docs/Web/API/fetch#priority
        // @ts-ignore
        priority,
        body: JSON.stringify({ query }),
      },
    );

    if (response.ok) {
      const json: ContentfulGraphQLResponse<T> = await response.json();

      return json.data;
    }

    return undefined;
  } catch (error: unknown) {
    captureError("Error when fetching data from Contentful's GraphQL API", {
      error,
      params: { query },
      tag: 'get-request',
    });

    return undefined;
  }
};

export default executeGraphQLQuery;
