import { ZDTTwilioSegment } from '@zalora/doraemon-ts';
import { ApiResponse } from 'types/api/ApiResponse';
import apiClient, { QueryParams } from './APIClient';

export const getTwilioSegment = async ({
  params,
  allData,
  next,
  segmentSpaceID,
  recall = true,
}: {
  params: QueryParams;
  allData: ZDTTwilioSegment.ProfilesTraitsAudience['Traits'];
  next?: string;
  segmentSpaceID: string;
  recall?: boolean;
}): Promise<ZDTTwilioSegment.ProfilesTraitsAudience['Traits'] | undefined> => {
  let allTrait = allData;

  try {
    const res = await apiClient.get(`/v1/twiliosegment/audience/traits`, {
      params: {
        ...params,
        space_id: segmentSpaceID,
        next_trait: next || '',
      },
      credentials: 'include',
    });

    const { data }: ApiResponse<ZDTTwilioSegment.ProfilesTraitsAudience> = await res.json();

    if (data && data.Traits) {
      allTrait = { ...allTrait, ...data.Traits };
    }

    if (data.Cursor && data.Cursor.HasMore && data.Cursor.NextTrait && recall) {
      return await getTwilioSegment({
        params,
        next: data.Cursor.NextTrait,
        allData: allTrait,
        segmentSpaceID,
        recall: false,
      });
    }

    return allTrait;
  } catch (e: unknown) {
    return undefined;
  }
};
