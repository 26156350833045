import { FlexiGrid as BaseFlexiGrid } from '@zalora/post-types';
import { BleedType, LoadingState } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import ObserverWrapper from 'components/Contentful/ObserverWrapper';
import { CONTENT_TYPE } from 'constants/contentful';
import {
  ANIMATION_POST_TYPE_CONFIG,
  DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD,
  FLEXI_GRID_COLUMNS,
} from 'constants/post-types';
import { useMediaPostType } from 'hooks/segment/usePostType';
import { onClickTrackingImageSegment, onPromotionView } from 'pages/segment/[segment].tracking';
import { RefinedFlexiGridProps } from 'types/post-types/FlexiGridTypes';
import { getDateTimePostType, isMediaAvailable } from 'utils/post-type/helper';
import MediaLink from './MediaLink';
import '@zalora/post-types/styles/flexi-grid.css';

const FlexiGrid: React.FC<RefinedFlexiGridProps> = ({
  dataPosition,
  className,
  post,
  activeKey,
  styles,
  ...props
}) => {
  const { displayMedia, trackCmsPageData, onImpression } = useMediaPostType({
    activeKey: activeKey || '',
    post,
    ...props,
  });

  const layout = useMemo(() => {
    if (!isMediaAvailable(displayMedia)) {
      return null;
    }

    const {
      bleedType,
      internalName = '',
      title = '',
      description = '',
      countdownTimerStart,
      countdownTimerEnd,
      countdownTimerText,
      postShelfRank,
    } = post;

    return (
      <BaseFlexiGrid
        className={clsx('mb-6 tablet:mb-8 desktop:mb-12', {
          [ANIMATION_POST_TYPE_CONFIG.CUSTOM_STYLE_CLASS_NAME as string]: !!post?.fontColor,
        })}
        internalName={internalName}
        title={title}
        description={description}
        media={displayMedia}
        countdownTimerText={countdownTimerText}
        countdownTimerStart={countdownTimerStart}
        countdownTimerEnd={countdownTimerEnd}
        getTime={getDateTimePostType}
        columns={FLEXI_GRID_COLUMNS}
        MediaLink={(props) => (
          <MediaLink
            onClick={() => {
              onClickTrackingImageSegment(trackCmsPageData, {
                ...props.data,
                xyPosition: `${dataPosition}-${props.data.position + 1}`,
                postType: CONTENT_TYPE.FLEXI_GRID,
                postShelfRank,
                postTitle: title,
                postInternalName: internalName,
              });
            }}
            {...props}
          />
        )}
        loadingState={LoadingState.Loaded}
        bleedType={bleedType || BleedType.Half}
        onImpression={(image) => {
          onPromotionView(trackCmsPageData, {
            ...image,
            xyPosition: `${dataPosition}-${image.position + 1}`,
            postType: CONTENT_TYPE.FLEXI_GRID,
            postShelfRank,
            postTitle: title,
            postInternalName: internalName,
          });
        }}
      />
    );
  }, [dataPosition, displayMedia, post, trackCmsPageData]);

  return (
    <div
      className={className}
      style={styles}
    >
      <ObserverWrapper
        threshold={DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD}
        onEvent={onImpression}
      >
        {layout}
      </ObserverWrapper>
    </div>
  );
};

export default memo(FlexiGrid);
