/* eslint-disable max-lines */
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchDataSourceAPI } from 'api/externalAPI';
import { CONTENT_TYPE } from 'constants/contentful';
import { OPTIMIZELY_VARIANT } from 'constants/optimizely';
import { AVAILABLE } from 'constants/post-types';
import { useTwilioUserTrait } from 'hooks/api/useTwilioUserTrait';
import useUser from 'hooks/api/useUser';
import { arrayToObject, isExternalMedia } from 'services/contentful/helper';
import { useLandingPageStore, useLandingPageDispatch } from 'stores/landingPage';
import { selectLandingPage } from 'stores/landingPage/selectors';
import {
  MediaWithPlacement,
  DataSource,
  RawExternalMedia,
  RawMediaPostType,
  SourceConfigData,
} from 'types/post-types/PostTypes';
import { getAnonymousId } from 'utils/anonymous-id';
import isServer from 'utils/is-server';
import { isMobileScreenSize } from 'utils/screen-size';
import {
  getExternalSourceData,
  normalizeExternalMediaItem,
  ruleDisplayMediaByType,
  isVisibleToPersonalizedUser,
} from './../../utils/post-type/helper';
import PostTypeContext from './PostTypeContext';

const defaultMediaPostTypeState: {
  sourceConfigOfPost?: DataSource[];
  mediaOfPost?: (MediaWithPlacement | RawExternalMedia)[];
} = {
  sourceConfigOfPost: [],
  mediaOfPost: [],
};

export interface PostTypeWithMediaProps {
  activeKey: string;
  post: RawMediaPostType;
  dataPosition?: number;
  renderedCallback?: () => void;
}

export const useMediaPostType = ({ activeKey, post, renderedCallback }: PostTypeWithMediaProps) => {
  const { updateFetchedExternalData, updateMediaExternalSource, removePostById } =
    useLandingPageDispatch();
  const router = useRouter();

  const decisions = useContext(PostTypeContext);

  const { isPersonalizePost = false } = post;

  // To enhance performance, data prioritization starts with mobile devices.
  // However, on the server, device type detection isn't possible.
  // Therefore, if the code is executing on the server, we consider it as a mobile environment.
  const isMobile = isServer() || isMobileScreenSize();

  const userTrait = useTwilioUserTrait();
  const { [activeKey]: segmentStoreData } = useLandingPageStore(selectLandingPage);

  const { data: user } = useUser();
  const zuid = user?.Zuid;
  const anonymousId = getAnonymousId();

  const [isLoading, setIsLoading] = useState(true);
  const [isImpressed, setImpression] = useState(false);
  const [displayMedia, setMediaDisplay] = useState([] as MediaWithPlacement[]);
  const [trackCmsPageData, setTrackingCmsPageData] = useState({
    title: '',
    contentTypeName: '',
    activeSegment: '',
  });
  const [statePostType, setMediaOfPost] = useState(defaultMediaPostTypeState);

  const { dataSources, mediaList, fetchedDataSources, pageType, title } = segmentStoreData;

  const isAbTestPost = useMemo(
    () =>
      post.media?.some((item) => {
        const itemMedia: MediaWithPlacement | RawExternalMedia | undefined =
          mediaList?.[item.entryId];

        if (itemMedia?.contentType === CONTENT_TYPE.INTERNAL_MEDIA) {
          return !!itemMedia.abTestName;
        }

        return false;
      }),
    [mediaList, post.media],
  );

  const updateMediaDisplayState = useCallback(
    (arrayMedia: MediaWithPlacement[]) => {
      const mediaWillDisplay = ruleDisplayMediaByType(arrayMedia, post);

      setMediaDisplay(
        mediaWillDisplay?.map((media, index) => {
          const priority = index === 0 ? 'high' : 'auto';

          return {
            ...media,
            priority,
            position: index,
          };
        }),
      );

      if (mediaWillDisplay?.length === 0) {
        setIsLoading(false);
        removePostById(activeKey as string, post?.entryId as string);
      }
    },
    [activeKey, post, removePostById],
  );

  const onImpression = () => {
    setImpression(true);
  };

  const fetchDataSource = useCallback(
    async (activeSegment?: string) => {
      const { mediaOfPost, sourceConfigOfPost } = statePostType;

      if (!sourceConfigOfPost?.length || !activeSegment || !mediaOfPost?.length) {
        return;
      }

      const fetchedData = await fetchDataSourceAPI({
        sourceConfigOfPost,
        activeSegment,
        zuid: user?.Zuid,
        locale: router.locale,
      });

      if (!fetchedData.length) {
        return [];
      }

      //normalize to dictionary to reduce loop to get the source belong to media.
      const fetchedSources = arrayToObject(fetchedData, 'idSource');

      const mappedMedia: { [key: string]: MediaWithPlacement } = {};

      const mediaArray: MediaWithPlacement[] = [];

      mediaOfPost?.forEach((media) => {
        //media is internal media or formatted external media
        if (media.contentType === CONTENT_TYPE.INTERNAL_MEDIA || media.isFetched) {
          mediaArray.push(media as MediaWithPlacement);

          return;
        }

        //this media is raw external media
        if (isExternalMedia(media)) {
          const { source, data } = media.sourceConfig || {};

          const externalSourceData =
            typeof source === 'number' && data
              ? getExternalSourceData<SourceConfigData>(data, fetchedSources?.[source].response)
              : {};

          if (externalSourceData.desktopImageUrl || externalSourceData.url) {
            mappedMedia[media.entryId] = normalizeExternalMediaItem(
              media,
              externalSourceData,
              AVAILABLE,
              isMobile,
            );
            mediaArray.push(mappedMedia[media.entryId]);
          }
        }
      });

      updateFetchedExternalData(activeSegment as string, fetchedSources);
      updateMediaExternalSource(activeSegment as string, mappedMedia);

      return mediaArray;
    },
    [
      isMobile,
      router.locale,
      statePostType,
      updateFetchedExternalData,
      updateMediaExternalSource,
      user?.Zuid,
    ],
  );

  const getMediaOfPost = useCallback(async () => {
    // case all media are internal
    const { sourceConfigOfPost, mediaOfPost } = statePostType;

    if (!sourceConfigOfPost?.length && mediaOfPost?.length) {
      return mediaOfPost as MediaWithPlacement[];
    }

    try {
      const list = await fetchDataSource(activeKey);

      return list?.length ? list : [];
    } catch (error) {
      return [];
    }
  }, [activeKey, fetchDataSource, statePostType]);

  /* only trigger once isImpressed and user-trait fetched
  This effect is impacted by 3 logic:
  Optimizely: if post type is post for ab test => wait the decisions are returned
  isViewed in view port: wait post on view port => display on screen if needed
  userTrait Segment and feature flag: wait userTrait return => display on screen if needed
  */
  useEffect(() => {
    //wait decisions is updated
    if (isAbTestPost && decisions && Object.keys(decisions).length === 0) {
      return;
    }

    //not update if post is not viewed or post is defined
    if (isImpressed === false || isLoading === false) {
      return;
    }

    // post is common post
    if (!isPersonalizePost || !userTrait.enablePersonalizeSegmentFlag) {
      getMediaOfPost().then((res) => {
        updateMediaDisplayState(res);
      });

      return;
    }

    const isUserTraitDefined = !userTrait.isLoading;
    const {
      enableSegmentAudienceFilter = false,
      segmentAudience = '',
      audienceBucket = [],
    } = post || {};

    // Do not enable personalization if the User Audience traits are yet to be loaded.
    if (!isUserTraitDefined) {
      return;
    }

    const userData = { zuid, anonymousId };
    const postData = { segmentAudience, audienceBucket };

    //post is enabled personalize and not belong to certain user
    if (
      enableSegmentAudienceFilter &&
      !isVisibleToPersonalizedUser(userData, postData, userTrait.data)
    ) {
      updateMediaDisplayState([]);

      return;
    }

    //post is enabled personalize and belong to certain user
    getMediaOfPost().then((mediaOfPost) => {
      const mediaResponse = mediaOfPost.reduce((acc, media) => {
        const { enableSegmentAudienceFilter, segmentAudience, audienceBucket } = media || {};

        if (!enableSegmentAudienceFilter) {
          return [...acc, media];
        }

        const mediaData = { segmentAudience, audienceBucket };

        if (isVisibleToPersonalizedUser(userData, mediaData, userTrait.data)) {
          return [...acc, media];
        }

        return [...acc];
      }, [] as MediaWithPlacement[]);

      updateMediaDisplayState(mediaResponse);
    });
  }, [
    userTrait.enablePersonalizeSegmentFlag,
    isImpressed,
    isPersonalizePost,
    updateMediaDisplayState,
    userTrait.data,
    userTrait.isLoading,
    getMediaOfPost,
    isLoading,
    post,
    isAbTestPost,
    decisions,
    zuid,
    anonymousId,
  ]);

  useEffect(() => {
    setTrackingCmsPageData({
      title: title || '',
      contentTypeName: pageType || '',
      activeSegment: activeKey,
    });
  }, [activeKey, pageType, title]);

  useEffect(() => {
    if (!post?.media || !post?.media?.length) {
      return;
    }

    const sourceConfigOfPost: DataSource[] = [];

    const mediaArray: (MediaWithPlacement | RawExternalMedia)[] = [];

    post.media.forEach((item) => {
      const itemMedia = item && mediaList && mediaList[item.entryId];

      if (!itemMedia) {
        return;
      }

      const { contentType, isFetched = false } = itemMedia;

      if (contentType === CONTENT_TYPE.INTERNAL_MEDIA || isFetched) {
        const mediaWithPlacement = itemMedia as MediaWithPlacement;
        const { abTestName: key = '' } = mediaWithPlacement;
        const decision = decisions?.[key];

        const isUseVariantMedia =
          userTrait.enablePersonalizeSegmentFlag &&
          decision?.enabled &&
          decision?.variationKey === OPTIMIZELY_VARIANT;

        if (isUseVariantMedia) {
          const variantMedia: MediaWithPlacement = {
            ...mediaWithPlacement,
            mediaUrl: mediaWithPlacement.mediaVariant?.mediaUrl || '',
            navLink: mediaWithPlacement.variantNavigationLink || '',
          };

          mediaArray.push(variantMedia);

          return;
        }

        mediaArray.push({
          ...mediaWithPlacement,
          mediaUrl: mediaWithPlacement.mediaData?.mediaUrl || '',
          navLink: mediaWithPlacement.navLink,
        });

        return;
      }

      if (isExternalMedia(itemMedia)) {
        const sourceId = itemMedia.sourceConfig?.source ?? '';

        //Check if the item is external media and meets certain conditions before adding to sourceConfigOfPost
        if (
          // ignore the source config already added
          !sourceConfigOfPost.some((item) => item.id === sourceId) &&
          // ignore if data source was fetched before
          !fetchedDataSources?.[sourceId] &&
          // if source existed in config
          dataSources?.[sourceId]
        ) {
          sourceConfigOfPost.push(dataSources[sourceId]);
        }

        mediaArray.push(itemMedia);

        return;
      }
    });

    setMediaOfPost({
      mediaOfPost: mediaArray,
      sourceConfigOfPost,
    });
  }, [
    dataSources,
    decisions,
    fetchedDataSources,
    mediaList,
    post,
    userTrait.enablePersonalizeSegmentFlag,
  ]);

  useEffect(() => {
    if (displayMedia.length > 0) {
      setIsLoading(false);

      if (renderedCallback) {
        renderedCallback();
      }
    }
  }, [displayMedia, renderedCallback]);

  return {
    fetchDataSource,
    getMediaOfPost,
    trackCmsPageData,
    isMobile,
    displayMedia,
    isLoading,
    onImpression,
    isImpressed,
  };
};
