import clsx from 'clsx';
import { FC, lazy, ReactNode, Suspense } from 'react';
import PriceDropSkeleton from 'components/price-drop/PriceDropSkeleton';
import { CONTENT_TYPE } from 'constants/contentful';
import {
  ANIMATION_TYPE,
  ANIMATION_POST_TYPE_CONFIG,
  POST_VARIANT_TYPE,
} from 'constants/post-types';
import useUser from 'hooks/api/useUser';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelectors from 'stores/optimizely/selectors';
import { RawCategoryGrid } from 'types/post-types/CategoryGridTypes';
import { AnimationType, RawPostType } from 'types/post-types/PostTypes';
import Banner from './Banner';
import Bubbles from './Bubbles';
import CategoryGrid from './CategoryGrid';
import FlexiGrid from './FlexiGrid';
import Grid from './Grid';
import { HTML } from './HTML';
import Recommendation from './Recommendation';
import TextPromotion from './TextPromotion';

const PriceDrop = lazy(() => import('components/price-drop/PriceDrop'));

const PostTypeRendered: FC<{
  activeKey: string;
  index: number;
  hasBackground: boolean;
  post: RawPostType;
  recFeedRank: number;
  renderedCallback: () => void;
  style: { [key: string]: string | undefined };
}> = ({ activeKey, post, index, hasBackground, renderedCallback, recFeedRank, style }) => {
  const isPriceDropEnabled = useOptimizelyStore(optimizelySelectors.isPriceDropEnabled);
  const { data: user } = useUser();

  const animation: AnimationType = (post.animation || '') as AnimationType;

  const wrapperClassNames = clsx({
    [ANIMATION_POST_TYPE_CONFIG.ANIMATION_WRAPPER_CLASS]: animation,
  });

  const animationClassNames = clsx(
    animation
      ? `${ANIMATION_POST_TYPE_CONFIG.ANIMATED_POST_CLASS} ${ANIMATION_TYPE[animation]}`
      : '',
  );

  let Component: ReactNode = null;

  switch (post.contentType) {
    case CONTENT_TYPE.BUBBLE: {
      Component = (
        <Bubbles
          activeKey={activeKey}
          className={clsx(wrapperClassNames, animationClassNames)}
          dataPosition={index + 1}
          post={post}
          renderedCallback={renderedCallback}
          styles={style}
        />
      );

      break;
    }

    case CONTENT_TYPE.GRID: {
      Component = (
        <Grid
          activeKey={activeKey}
          className={clsx(wrapperClassNames, animationClassNames)}
          dataPosition={index + 1}
          post={post}
          renderedCallback={renderedCallback}
          styles={style}
        />
      );

      break;
    }

    case CONTENT_TYPE.TEXT_BANNER: {
      Component = (
        <TextPromotion
          activeKey={activeKey}
          className={clsx(wrapperClassNames, animationClassNames)}
          dataPosition={index + 1}
          post={post}
          renderedCallback={renderedCallback}
          styles={style}
        />
      );

      break;
    }

    case CONTENT_TYPE.BANNER: {
      Component = (
        <Banner
          activeKey={activeKey}
          className={clsx(wrapperClassNames, animationClassNames)}
          dataPosition={index + 1}
          post={post}
          renderedCallback={renderedCallback}
          styles={style}
        />
      );

      break;
    }

    case CONTENT_TYPE.FLEXI_GRID: {
      if (post.postVariant === POST_VARIANT_TYPE.C1C5) {
        Component = (
          <CategoryGrid
            activeKey={activeKey}
            className={clsx(wrapperClassNames, animationClassNames)}
            dataPosition={index + 1}
            post={post as RawCategoryGrid}
            renderedCallback={renderedCallback}
            styles={style}
          />
        );
      } else {
        Component = (
          <FlexiGrid
            activeKey={activeKey}
            className={clsx(wrapperClassNames, animationClassNames)}
            dataPosition={index + 1}
            post={post}
            renderedCallback={renderedCallback}
            styles={style}
          />
        );
      }

      break;
    }

    case CONTENT_TYPE.RECOMMENDATION: {
      Component = (
        <Recommendation
          activeKey={activeKey}
          className={clsx(wrapperClassNames, animationClassNames)}
          dataPosition={index + 1}
          hasBackground={hasBackground}
          post={post}
          recFeedRank={recFeedRank}
          renderedCallback={renderedCallback}
          styles={style}
        />
      );

      break;
    }

    case CONTENT_TYPE.HTML: {
      Component = (
        <HTML
          activeKey={activeKey}
          className={clsx(wrapperClassNames, animationClassNames)}
          dataPosition={index + 1}
          post={post}
          renderedCallback={renderedCallback}
          styles={style}
        />
      );

      break;
    }

    default: {
      break;
    }
  }

  if (isPriceDropEnabled && post.abtestFlagName === 'price_drop_tracker' && !!user) {
    return (
      <>
        {Component}

        <Suspense fallback={<PriceDropSkeleton />}>
          <PriceDrop modulePosition={post.postShelfRank} />
        </Suspense>
      </>
    );
  }

  return Component;
};

export default PostTypeRendered;
