import { ZDTPromotion } from '@zalora/doraemon-ts';
import useSWRImmutable from 'swr/immutable';
import { getPromoByConfigSkuList } from 'api/promotions';
import { splitToChunks } from 'utils/array';

const SKU_LIMIT = 60;

export const parallelFetch = async (skus: string[]) => {
  const skusPerChunk = splitToChunks(skus, SKU_LIMIT);

  const res = await Promise.all(skusPerChunk.map((chunk) => getPromoByConfigSkuList(chunk)));

  return res.reduce<ZDTPromotion.PromotionMap>(
    (prev, chunk) => {
      if (!chunk) {
        return prev;
      }

      const prevGifts = prev.Gifts || {};
      const prevPromotions = prev.Promotions || {};
      const gifts = chunk.Gifts || {};
      const promotions = chunk.Promotions || {};

      return {
        Promotions: { ...prevPromotions, ...promotions },
        Gifts: { ...prevGifts, ...gifts },
      };
    },
    { Promotions: null, Gifts: null },
  );
};

export const usePromotion = (skus: string[]) => {
  const promotionData = useSWRImmutable(
    skus.length > 0 ? ['/v1/promotions', skus] : null,
    ([, skus]) => parallelFetch(skus),
  );

  return promotionData;
};
