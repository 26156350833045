import { Grid as BaseGrid } from '@zalora/post-types';
import { Platform } from '@zalora/post-types/lib/Grid/types';
import { BleedType, LoadingState } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import ObserverWrapper from 'components/Contentful/ObserverWrapper';
import { SkeletonGrid } from 'components/skeleton/SkeletonGrid';
import { CONTENT_TYPE } from 'constants/contentful';
import {
  ANIMATION_POST_TYPE_CONFIG,
  DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD,
} from 'constants/post-types';
import { useMediaPostType } from 'hooks/segment/usePostType';
import { onClickTrackingImageSegment, onPromotionView } from 'pages/segment/[segment].tracking';
import { RefinedGridProps } from 'types/post-types/GridTypes';
import { getDateTimePostType, isMediaAvailable } from 'utils/post-type/helper';
import LeftButton from './LeftButton';
import MediaLink from './MediaLink';
import RightButton from './RightButton';
import '@zalora/post-types/styles/grid.css';

const carouselOption = {
  LeftButton,
  RightButton,
};

const Grid: React.FC<RefinedGridProps> = ({
  dataPosition,
  className,
  post,
  styles,
  activeKey,
  ...props
}) => {
  const { displayMedia, trackCmsPageData, isMobile, isLoading, onImpression } = useMediaPostType({
    activeKey: activeKey || '',
    post,
    ...props,
  });

  const layout = useMemo(() => {
    const {
      bleedType,
      internalName = '',
      title = '',
      description = '',
      countdownTimerStart,
      countdownTimerEnd,
      countdownTimerText,
      ctaLabel = '',
      ctaNavigationLink = '',
      postShelfRank,
      rows,
      columns,
      scrollOffset,
    } = post;

    return (
      <>
        {isLoading ? <SkeletonGrid post={post} /> : null}

        <div
          className={clsx({
            hidden: isLoading || !isMediaAvailable(displayMedia),
            'mb-6 tablet:mb-8 desktop:mb-12': !isLoading && isMediaAvailable(displayMedia),
          })}
        >
          <BaseGrid
            className={clsx({
              [ANIMATION_POST_TYPE_CONFIG.CUSTOM_STYLE_CLASS_NAME as string]: !!post?.fontColor,
            })}
            internalName={internalName}
            title={title}
            description={description}
            media={displayMedia}
            countdownTimerText={countdownTimerText}
            countdownTimerStart={countdownTimerStart}
            countdownTimerEnd={countdownTimerEnd}
            ctaLabel={ctaLabel}
            ctaLink={ctaNavigationLink}
            rows={rows || 0}
            columns={columns || 0}
            columnsPerView={scrollOffset ? +scrollOffset + 0.5 : undefined}
            getTime={getDateTimePostType}
            carouselOption={carouselOption}
            MediaLink={(props) => (
              <MediaLink
                onClick={() => {
                  onClickTrackingImageSegment(trackCmsPageData, {
                    ...props.data,
                    xyPosition: `${dataPosition}-${props.data.position + 1}`,
                    postType: CONTENT_TYPE.GRID,
                    postShelfRank,
                    postTitle: title,
                    postInternalName: internalName,
                  });
                }}
                {...props}
              />
            )}
            loadingState={LoadingState.Loaded}
            platform={isMobile ? Platform.Mweb : Platform.Desktop}
            bleedType={bleedType || BleedType.Half}
            onImpression={(image) => {
              onPromotionView(trackCmsPageData, {
                ...image,
                xyPosition: `${dataPosition}-${image.position + 1}`,
                postType: CONTENT_TYPE.GRID,
                postShelfRank,
                postTitle: title,
                postInternalName: internalName,
              });
            }}
          />
        </div>
      </>
    );
  }, [dataPosition, displayMedia, post, trackCmsPageData, isMobile, isLoading]);

  return (
    <div
      className={className}
      style={styles}
    >
      <ObserverWrapper
        threshold={DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD}
        onEvent={onImpression}
      >
        {layout}
      </ObserverWrapper>
    </div>
  );
};

export default memo(Grid);
