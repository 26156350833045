import { ArrowRightIcon } from '@zalora/zui-icons';
import React from 'react';

const RightButton: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
  <ArrowRightIcon
    {...props}
    width={24}
    height={24}
  />
);

export default RightButton;
