export const enum Constants {
  DEMO_IMAGE_WIDTH_MOBILE = 360,
  DEMO_IMAGE_HEIGHT_MOBILE = 222,
  DEMO_IMAGE_WIDTH_TABLET = 996,
  DEMO_IMAGE_HEIGHT_TABLET = 311,

  PRODUCT_IMAGE_WIDTH_MOBILE = 134,
  PRODUCT_IMAGE_HEIGHT_MOBILE = 193,
  PRODUCT_IMAGE_WIDTH_TABLET = 186,
  PRODUCT_IMAGE_HEIGHT_TABLET = 268,

  SKELETON_ITEM_HEIGHT_MOBILE = 204,
  SKELETON_ITEM_HEIGHT_TABLET = 302,
}
