import { ComponentPropsWithoutRef, PropsWithChildren, useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';
import { PostStyles } from 'types/post-types/PostTypes';

export interface Props extends ComponentPropsWithoutRef<'div'> {
  onEvent: () => void;
  threshold: number;
  rootMargin?: string;
  styles?: PostStyles;
  className?: string;
}

const ObserverWrapper = ({
  onEvent,
  children,
  threshold,
  rootMargin = '',
  styles,
  className,
  ...props
}: PropsWithChildren<Props>) => {
  const ref = useRef<null>(null);
  const entry = useIntersectionObserver(ref, {
    threshold,
    freezeOnceVisible: true,
    rootMargin,
  });
  const { isIntersecting } = entry || {};
  const isVisible = !!isIntersecting;

  useInvokeCallbackOnceWhen(onEvent, isVisible);

  return (
    <div
      ref={ref}
      className={className}
      style={styles}
      {...props}
    >
      {children}
    </div>
  );
};

export default ObserverWrapper;
