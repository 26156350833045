import { useEffect } from 'react';
import HtmlBlockWithDangerScript from 'components/HtmlBlock/HtmlBlockWithDangerScript';
import { useTwilioUserTrait } from 'hooks/api/useTwilioUserTrait';
import { trackingStaticBannerHTMLContentful } from 'pages/segment/[segment].tracking';
import { useLandingPageDispatch } from 'stores/landingPage';
import { HTMLProps } from 'types/post-types/HTMLTypes';
import { isMobileScreenSize } from 'utils/screen-size';

const ID_HTML_CONTENTFUL = 'cms-contentful';

export const HTML: React.FC<HTMLProps> = ({
  post,
  activeKey = '',
  className,
  styles,
  renderedCallback,
}) => {
  const isMobile = isMobileScreenSize();
  const {
    contentDweb: desktopContent,
    contentMweb: mwebContent,
    entryId = '',
    enableSegmentAudienceFilter,
    segmentAudience,
    audienceBucket,
  } = post || {};
  const contentData = isMobile ? mwebContent : desktopContent;

  const { removePostById } = useLandingPageDispatch();

  useTwilioUserTrait({
    enableSegmentAudienceFilter,
    segmentAudience,
    audienceBucket,
    callbackAction: () => removePostById(activeKey, entryId),
  });

  useEffect(() => {
    if (renderedCallback) {
      renderedCallback();
    }

    trackingStaticBannerHTMLContentful(ID_HTML_CONTENTFUL, activeKey);
  }, [activeKey, renderedCallback]);

  return (
    <div
      className={className}
      style={styles}
      id={`${ID_HTML_CONTENTFUL}-${entryId}`}
    >
      <HtmlBlockWithDangerScript
        className={`static-block-${entryId} post-type-wrapper`}
        id={`static-block-${entryId}`}
        content={contentData?.data || ''}
      />
    </div>
  );
};
