import { BleedType } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { CONTENT_TYPE } from 'constants/contentful';
import { PRE_WRAPPER_CLASS } from 'constants/post-types';
import { TestIdsSegment } from 'constants/segment-test-ids';
import PostTypeContext from 'hooks/segment/PostTypeContext';
import { useSendOptimizelyDecisionForKeys } from 'hooks/useOptimizelyDecision';
import { FetchStatus, PostStyles, PostTypeProps } from 'types/post-types/PostTypes';
import { getPostStyles } from 'utils/post-type/helper';
import AnimationWrapper from './AnimationWrapper';
import PostTypeRendered from './PostTypeRendered';

const PostTypeLayout: FC<PostTypeProps> = ({
  activeKey,
  backgroundColor,
  fetchStatus,
  listAbTestFlag,
  posts,
  postsSpacing = '',
  renderedCallback,
}) => {
  const { decisions } = useSendOptimizelyDecisionForKeys(listAbTestFlag || []);

  let recFeedRank = 0;

  useEffect(() => {
    if (fetchStatus === FetchStatus.Loaded && posts?.length === 0) {
      renderedCallback();
    }
  }, [fetchStatus, posts, renderedCallback]);

  return (
    <PostTypeContext.Provider value={decisions}>
      <div
        className="flex flex-col overflow-hidden"
        style={{ backgroundColor: backgroundColor || 'transparent' }}
      >
        {posts?.map((post, index) => {
          if (post.contentType === CONTENT_TYPE.RECOMMENDATION) {
            recFeedRank++;
          }

          const {
            animation,
            backgroundColor: backgroundColorOfPost,
            backgroundImage,
            bleedType,
            entryId,
            fontColor,
          } = post;

          const styles: PostStyles = getPostStyles({
            fontColor: fontColor || '',
            backgroundColor: backgroundColorOfPost || '',
            backgroundImageUrl: backgroundImage?.file?.url || '',
          });

          const spacing = postsSpacing ? { marginBottom: `${postsSpacing}px` } : {};
          const style = { ...styles, ...spacing };

          const bleedClassName =
            bleedType === BleedType.Full
              ? PRE_WRAPPER_CLASS.FULL_BLEED_PRE_WRAPPER_CLASS
              : PRE_WRAPPER_CLASS.HALF_BLEED_PRE_WRAPPER_CLASS;

          return (
            <AnimationWrapper
              key={`${entryId}-${index}`}
              animation={animation}
              className={clsx('overflow-hidden', bleedClassName)}
              data-key={`${entryId}-${index}`}
              data-position={index + 1}
              data-test-id={TestIdsSegment.CONTENTFUL_POST}
            >
              <PostTypeRendered
                activeKey={activeKey}
                hasBackground={!!backgroundColorOfPost}
                index={index}
                post={post}
                recFeedRank={recFeedRank}
                renderedCallback={renderedCallback}
                style={style}
              />
            </AnimationWrapper>
          );
        })}
      </div>
    </PostTypeContext.Provider>
  );
};

export default PostTypeLayout;
