import { CONTENT_TYPE } from 'constants/contentful';
import { getEntriesByOptions } from 'services/contentful/contentfulSDK';
import { isTrueValue } from 'services/contentful/helper';
import { getRequiredParams } from 'services/contentful/params';
import pdvContentBlockCollection from 'services/contentful/queries/pdvContentBlockCollection';
import {
  getContentByEntryIdQuery,
  getSegmentContentTypeQuery,
  getStaticPageContentTypeQuery,
} from 'services/contentful/queries/segmentQuery';
import { EntryResponse } from 'types/contentful/ContentfulType';
import { getCountryCode } from 'utils/countries';
import { captureError } from 'utils/raven';

export const fetchContentfulPostsBySegment = async (
  segment: string,
  options: {
    isPreviewMode?: boolean;
    entryId?: string;
    previewTime?: string;
    isMobile: boolean;
    segment?: string;
    locale: string;
    shouldDisableMediaTimers?: boolean;
  },
) => {
  try {
    const countryCode = getCountryCode()?.toUpperCase() || '';
    const {
      entryId = '',
      isPreviewMode = false,
      locale,
      isMobile,
      previewTime = '',
      shouldDisableMediaTimers = false,
    } = options;

    const query = isPreviewMode
      ? getContentByEntryIdQuery(entryId, locale, CONTENT_TYPE.SEGMENT, isMobile)
      : getSegmentContentTypeQuery(segment, countryCode, locale, isMobile);

    const data = await getEntriesByOptions(
      { query },
      {
        isPreviewMode,
        previewTime,
        shouldDisableMediaTimers,
      },
      isMobile,
    );

    return data;
  } catch (error) {
    captureError('Error when fetching segment data from CONTENTFUL', {
      error,
      tag: 'get-request',
      params: {
        segment,
        options,
      },
    });
  }

  return null;
};

export const fetchStaticPageData = async (
  cmsKey: string,
  options: {
    isPreviewMode?: boolean;
    entryId?: string;
    previewTime?: string;
    isMobile: boolean;
    segment?: string;
    locale: string;
  },
): Promise<Nullable<EntryResponse>> => {
  if (!cmsKey) {
    return null;
  }

  try {
    const countryCode = getCountryCode()?.toUpperCase() || '';
    const { entryId = '', isPreviewMode = false, locale, isMobile, previewTime = '' } = options;
    const query = isTrueValue(isPreviewMode)
      ? getContentByEntryIdQuery(entryId, locale, CONTENT_TYPE.STATIC_PAGE, isMobile)
      : getStaticPageContentTypeQuery({ cmsKey, countryISO: countryCode, locale, isMobile });

    const data = await getEntriesByOptions(
      { query },
      {
        isPreviewMode: isTrueValue(isPreviewMode),
        previewTime,
      },
      isMobile,
    );

    return data;
  } catch (error) {
    captureError('Error when fetching static page data from CONTENTFUL', {
      error,
      tag: 'get-request',
      params: { cmsKey, options },
    });
  }

  return null;
};

export const pdvContentBlockData = async (locale: string) => {
  try {
    return pdvContentBlockCollection({
      lang: getRequiredParams(locale as string).lang,
      items: [
        'uspTitleAuthentic',
        'uspTitleReturnExchangeEligible',
        'uspTitleReturnExchangeRestricted',
        'uspTitleFastDelivery',

        'uspContentAuthentic',
        'uspContentReturnExchangeEligible',
        'uspContentReturnExchangeRestricted',
        'uspContentFastDelivery',
      ],
    });
  } catch (error) {
    captureError('Error when fetching pdvContentBlock data from CONTENTFUL', {
      error,
      tag: 'get-request',
    });
  }
};
