import { getCountryCode } from 'utils/countries';

export const getContentfulLocale = (lang: string): string => {
  const countryISO = getCountryCode();

  if (lang === 'zh') {
    return `${lang}-Hant-${countryISO?.toLocaleUpperCase()}`;
  }

  return lang;
};
