import { OptimizelyActions, OptimizelyObservables, OptimizelyStore } from './types';

export const isPriceDisplayEnabled = (state: OptimizelyObservables) => {
  return state.isPriceDisplayEnabled;
};

export const isSocialProofingEnabled = (state: OptimizelyObservables) => {
  return state.isSocialProofingEnabled;
};

export const isBestPriceGuaranteeReady = (state: OptimizelyObservables) => {
  return state.isBestPriceGuaranteeReady;
};

export const bestPriceGuaranteeValue = (state: OptimizelyObservables) => {
  return state.bestPriceGuaranteeValue;
};

export const shouldShowSomethingSimilarPDV = (state: OptimizelyObservables) => {
  return state.shouldShowSomethingSimilarPDV;
};

export const isPriceDropEnabled = (state: OptimizelyObservables) => {
  return state.priceDropData.isEnabled;
};

export const priceDropCTALinks = (state: OptimizelyObservables) => {
  return state.priceDropData.ctaLinks;
};

export const actions = (state: OptimizelyStore): Pick<OptimizelyStore, keyof OptimizelyActions> => {
  return {
    setIsPriceDisplayEnabled: state.setIsPriceDisplayEnabled,
    setIsSocialProofingEnabled: state.setIsSocialProofingEnabled,
    setIsBestPriceGuaranteeReady: state.setIsBestPriceGuaranteeReady,
    setBestPriceGuaranteeValue: state.setBestPriceGuaranteeValue,
    setShouldShowSomethingSimilarPDV: state.setShouldShowSomethingSimilarPDV,
    setPriceDropData: state.setPriceDropData,
  };
};
