import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';
import { TestIdsPriceDrop } from 'constants/e2eIds/price-drop-test-ids';
import { Constants } from 'constants/price-drop';
import { useDeviceType } from 'hooks/useDeviceType';

const PriceDropSkeleton: FC = () => {
  const { isMobile } = useDeviceType();

  const height = isMobile
    ? Constants.SKELETON_ITEM_HEIGHT_MOBILE
    : Constants.SKELETON_ITEM_HEIGHT_TABLET;

  return (
    <div
      className="relative m-auto mb-4 flex desktop:mb-6"
      data-test-id={TestIdsPriceDrop.SKELETON}
    >
      <SkeletonItem
        className="mx-auto w-full pt-8"
        style={{ height }}
      />
    </div>
  );
};

export default PriceDropSkeleton;
