import { getContentfulLocale } from 'services/contentful/helper/getContentfulLocale';
import executeGraphQLQuery from './executeGraphQLQuery';

export type PDVContentBlockCollectionResponse = {
  pdvContentBlockCollection: PDVContentBlockCollection;
};

export type PDVContentBlock = {
  emiPaymentBlock: string;
  uspTitleReturnExchangeEligible: string;
  uspTitleAuthentic: string;
  uspTitleReturnExchangeRestricted: string;
  uspTitleFastDelivery: string;
  uspContentAuthentic: string;
  uspContentReturnExchangeEligible: string;
  uspContentReturnExchangeRestricted: string;
  uspContentFastDelivery: string;
};

export type PDVContentBlockCollection = {
  items: PDVContentBlock[];
};

interface Props {
  lang: string;
  items: string[];
}

const pdvContentBlockCollection = async ({ lang = 'en', items }: Props) => {
  return await executeGraphQLQuery<PDVContentBlockCollectionResponse>(
    `{
      pdvContentBlockCollection (
        locale: "${getContentfulLocale(lang)}"
      ) {
        items {
          ${items.join(' ')}
        }
      }
     }
 `,
  );
};

export default pdvContentBlockCollection;
