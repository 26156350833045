/* eslint-disable max-lines */
import { ProductElement } from '@zalora/post-types/lib/common/types';
import { isUrl } from 'api/externalAPI';
import {
  REC_FEED_PRODUCT_CLICKED,
  REC_FEED_PRODUCT_VIEWED,
  REC_FEED_PRODUCT_CLICKED_ITEM_LEVEL,
  REC_FEED_PRODUCT_VIEWED_ITEM_LEVEL,
  REC_FEED_PRODUCT_ADDED_TO_WISHLIST,
  REC_FEED_PRODUCT_ADDED_TO_WISHLIST_ITEM_LEVEL,
  REC_FEED_PRODUCT_ADDED_TO_BAG,
  REC_FEED_PRODUCT_ADDED_TO_BAG_ITEM_LEVEL,
  RecFeedLayout,
} from 'constants/tracking';
import { RecommendationFeedData } from 'types/DatajetRecommendation';
import {
  ProductElementPostType,
  SegmentTrackingData,
} from 'types/trackingPayload/HomePageTrackingPayload';
import { getCountryCode } from 'utils/countries';
import { getCurrencyCode } from 'utils/money';
import * as SegmentTracking from 'utils/segment-analytic';
import { getListId, prepareDataTracking } from 'utils/tracking/common';

export const viewedProductImpression: ProductElementTracking[] = [];
let viewProductTimer: Nullable<ReturnType<typeof setTimeout>> = null;

const VIEW_PRODUCT_DEBOUNCE_TIME = 500;

export interface ProductElementTracking extends ProductElementPostType {
  titleRecFeed?: string;
  placement?: string;
  trackUrl?: string;
}

const ALL_SEGMENT = 'All';

export const SEGMENT_IMPRESS_EVENT = 'Impression';
export const SEGMENT_CLICK_EVENT = 'Click';

export const onProductImpression = ({
  product,
  dataPosition,
  typeVenderSource,
  platformSource,
  segment,
  recFeedRank,
  moduleTitle,
  promotionInternalName,
}: {
  product: ProductElementTracking;
  dataPosition?: string;
  typeVenderSource: string;
  platformSource: string;
  segment: string;
  recFeedRank: number;
  moduleTitle?: string;
  promotionInternalName?: string;
}) => {
  if (product) {
    const countryISO = getCountryCode();

    viewedProductImpression.push({
      ...product,
      countrySKU: `${countryISO?.toUpperCase()}${product.sku}`,
    });
  }

  const { titleRecFeed } = product;

  onProductView({
    titleRecFeed,
    modulePosition: dataPosition,
    typeVenderSource,
    platformSource,
    segment,
    recFeedRank,
    moduleTitle,
    promotionInternalName,
  });
};

export const onProductView = ({
  titleRecFeed,
  placementId,
  modulePosition,
  typeVenderSource,
  segment,
  platformSource,
  recFeedRank,
  moduleTitle,
  promotionInternalName,
}: {
  titleRecFeed?: string;
  placementId?: string;
  modulePosition?: string;
  typeVenderSource?: string;
  segment?: string;
  platformSource: string;
  recFeedRank: number;
  moduleTitle?: string;
  promotionInternalName?: string;
}) => {
  if (viewProductTimer) {
    clearTimeout(viewProductTimer);
  }

  viewProductTimer = setTimeout(() => {
    triggerSegmentTracking(
      {
        products: viewedProductImpression,
        vendorSource: typeVenderSource,
        listId: titleRecFeed,
        placementId,
        modulePosition,
        segment,
        recFeedRank,
        moduleTitle,
        promotionInternalName,
      },
      SEGMENT_IMPRESS_EVENT,
      platformSource,
    );
    viewedProductImpression.length = 0;
  }, VIEW_PRODUCT_DEBOUNCE_TIME);
};

export const trackingOnRecFeedProductAddedToWishlist = async ({
  configSku,
  modulePosition,
  vendor,
  id,
  xPosition,
  listId,
  parentSKU,
  recFeedModulePosition,
  moduleTitle,
  promotionInternalName,
}: {
  configSku: string;
  modulePosition?: string;
  vendor: RecommendationFeedData['vendor'];
  id: string;
  xPosition?: string;
  listId?: string;
  parentSKU?: string;
  recFeedModulePosition?: number;
  moduleTitle?: string;
  promotionInternalName?: string;
}) => {
  const payload = await prepareDataTracking({
    configSku,
    modulePosition,
    vendor,
    id,
    xPosition,
    listId,
    parentSKU,
    recFeedModulePosition,
    moduleTitle,
    promotionInternalName,
  });

  SegmentTracking.track(REC_FEED_PRODUCT_ADDED_TO_WISHLIST, payload?.wishlistTrackingData);

  SegmentTracking.track(
    REC_FEED_PRODUCT_ADDED_TO_WISHLIST_ITEM_LEVEL,
    payload?.wishlistItemLevelTrackingData,
  );
};

export const trackingOnRecFeedProductAddedToBag = async ({
  configSku,
  modulePosition,
  vendor,
  id,
  xPosition,
  listId,
  parentSKU,
  recFeedModulePosition,
  moduleTitle,
  promotionInternalName,
  layoutType,
}: {
  configSku: string;
  modulePosition?: string;
  vendor: RecommendationFeedData['vendor'];
  id: string;
  xPosition?: string;
  listId?: string;
  parentSKU?: string;
  recFeedModulePosition?: number;
  moduleTitle?: string;
  promotionInternalName?: string;
  layoutType?: string;
}) => {
  const payload = await prepareDataTracking({
    configSku,
    modulePosition,
    vendor,
    id,
    xPosition,
    listId: listId || getListId(),
    parentSKU,
    recFeedModulePosition,
    moduleTitle,
    promotionInternalName,
    layoutType,
  });

  SegmentTracking.track(REC_FEED_PRODUCT_ADDED_TO_BAG, payload?.wishlistTrackingData);

  SegmentTracking.track(
    REC_FEED_PRODUCT_ADDED_TO_BAG_ITEM_LEVEL,
    payload?.wishlistItemLevelTrackingData,
  );
};

export const triggerSegmentTracking = (
  {
    products,
    placementId,
    parentSKU,
    modulePosition,
    listId,
    vendorSource,
    segment,
    recFeedRank,
    moduleTitle,
    promotionInternalName,
  }: {
    products: ProductElementTracking[];
    vendorSource?: string;
    listId?: string;
    placementId?: string;
    parentSKU?: { sku?: string };
    modulePosition?: string;
    segment?: string;
    recFeedRank?: number;
    moduleTitle?: string;
    promotionInternalName?: string;
  },
  action: string,
  platformSource: string,
) => {
  if (!products.length) {
    return;
  }

  const sourceSegment = segment;
  const pageType = 'segment';
  const pageTypeListId = getSourcePageType({
    pageType,
    segment: sourceSegment,
  });
  const elementPlacement = placementId ? document.getElementById(placementId) : '';
  const positionRecFeed =
    segment && elementPlacement && placementId
      ? elementPlacement.getAttribute('data-position')
      : `${modulePosition}`;

  const segmentData = {
    platform_source: platformSource,
    source_segment: sourceSegment,
    source_page_type: pageTypeListId.pageType,
    list_id: listId || pageTypeListId.listId,
    vendor_source: vendorSource || '',
    module_position: positionRecFeed || '',
    parentSKU: parentSKU?.sku || null,
    placementID: placementId || '',
    x_position: `${products?.[0]?.position}` || '',
    recfeed_rank: recFeedRank ? recFeedRank.toString() : '',
    module_title: moduleTitle || '',
  };

  // Revamp structure and additional parameters of RecFeed events
  recFeedProductItemLevelTracking(segmentData, products, action, promotionInternalName);

  const trackingData = {
    ...segmentData,
    products: products.map((product) => covertToSegmentProduct(product)),
  };

  switch (action) {
    case SEGMENT_IMPRESS_EVENT: {
      SegmentTracking.track(REC_FEED_PRODUCT_VIEWED, trackingData);
      break;
    }

    case SEGMENT_CLICK_EVENT: {
      trackingData.x_position =
        typeof products?.[0]?.position === 'number' ? (products[0].position + 1).toString() : '';

      trackingData.x_position = products?.[0]?.position
        ? (products[0].position + 1).toString()
        : '';
      trackingData.source_segment = sourceSegment;
      SegmentTracking.track(REC_FEED_PRODUCT_CLICKED, trackingData);

      if (products[0]?.trackUrl) {
        fetch(products[0].trackUrl);
      }

      break;
    }

    default: {
      return;
    }
  }
};

export const recFeedProductItemLevelTracking = (
  segmentData: SegmentTrackingData,
  products: ProductElementPostType[],
  action: string,
  promotionInternalName?: string,
) => {
  products.forEach((product) => {
    const productData = {
      ...segmentData,
      recfeed_layout: RecFeedLayout.ROW, // Homepage have only Row layout for RecFeed modules
      y_position: '1', // If the recfeed is in row layout, then value stays 1
      x_position: `${product.position + 1}`, // The column position of each sku
      promotion_post_shelf_rank: `${product.position + 1}`,
      promotion_post_title: segmentData.module_title,
      promotion_post_internal_name: promotionInternalName || '',
      ...covertToSegmentProduct(product),
    };

    switch (action) {
      case SEGMENT_IMPRESS_EVENT: {
        SegmentTracking.track(REC_FEED_PRODUCT_VIEWED_ITEM_LEVEL, productData);
        break;
      }

      case SEGMENT_CLICK_EVENT: {
        SegmentTracking.track(REC_FEED_PRODUCT_CLICKED_ITEM_LEVEL, productData);
        break;
      }
    }
  });
};

export const covertToSegmentProduct = (product?: ProductElement) => {
  if (!product) {
    return;
  }

  const {
    brand,
    imageURL,
    productURL,
    name,
    sku,
    price,
    category,
    color,
    gender,
    subcategory,
    subcategoryBreadcrumb,
    marketplace,
    trackingURL,
    countrySKU,
  } = product;
  const url = trackingURL || productURL || '';
  const curPrice = price?.current?.fullPrice;
  const prevPrice = price?.previous?.fullPrice;

  return {
    category,
    color,
    gender,
    marketplace,
    name,
    sku,
    subcategory,
    brand,
    countrySKU,
    image_url: imageURL,
    on_sale: curPrice && prevPrice && prevPrice > curPrice,
    price: curPrice,
    price_original: prevPrice,
    subcategory_breadcrumb: subcategoryBreadcrumb,
    url: isUrl(url) ? url : `${window.location.origin}/${url}`,
    currency: getCurrencyCode(),
  };
};

export const getSourcePageType = ({
  pageType,
  segment,
}: {
  pageType?: string;
  segment?: string;
}) => {
  // There are some pages which requires some dynamic data
  switch (pageType) {
    case 'segment': {
      return {
        pageType: 'Segmentpage',
        listId: typeof segment === 'string' ? segment.toLocaleUpperCase() : ALL_SEGMENT,
      };
    }

    default: {
      return {
        pageType,
        listId: pageType,
      };
    }
  }
};
