import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import { RefinedFlexiGridProps } from 'types/post-types/FlexiGridTypes';
import Banner from './Banner';
import DesktopFlexiGrid from './DesktopFlexiGrid';

const FlexiGrid: React.FC<RefinedFlexiGridProps> = (props) => {
  const layout = useMemo(() => {
    return (
      <>
        <Banner
          isShowPaging
          ratio={2 / 3}
          {...props}
          className={clsx(props.className, 'tablet:hidden desktop:hidden')}
        />
        <DesktopFlexiGrid
          {...props}
          className={clsx(props.className, 'hidden tablet:block')}
        />
      </>
    );
  }, [props]);

  return layout;
};

export default memo(FlexiGrid);
