import { ScreenType } from 'services/contentful/types';
import { getCountryCode } from 'utils/countries';
import { getEnvConfiguration } from 'utils/env-configuration';
import { getLanguage } from 'utils/locale';

export enum SCREEN_TYPES {
  CATALOG = 'catalog',
  MY_ACCOUNT = 'myAccount',
  MY_ORDERS = 'myOrders',
  PDV = 'pdv',
  CART = 'cart',
}

export type ContentfulRequiredParams = {
  countryCode: ReturnType<typeof getEnvConfiguration>;
  lang?: string;
  screenType?: ScreenType;
};

export const getRequiredParams = (locale?: string) => {
  return {
    countryCode: getCountryCode()?.toUpperCase(),
    lang: getLanguage(locale),
  };
};
