import { Button } from '@zalora/zui';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { ComponentProps, FC } from 'react';

interface Props {
  className?: string;
  onClick: () => void;
  variant: ComponentProps<typeof Button>['variant'];
}

const AddToBagButton: FC<Props> = ({ className, onClick, variant }) => {
  const { t } = useTranslation();

  return (
    <Button
      className={clsx('mt-auto h-10 min-h-[40px] rounded-lg tablet:h-12', className)}
      variant={variant}
      onClick={onClick}
      aria-label={t('Add to Bag')}
    >
      {t('Add to Bag')}
    </Button>
  );
};

export default AddToBagButton;
