/* eslint-disable max-lines */
import { ZDTPriceDrop } from '@zalora/doraemon-ts';
import {
  getBrandName,
  getBreadcrumbs,
  getCurrentPrice,
  getImageUrl,
  getInitialPrice,
  getOriginalPrice,
  getPriceDrop,
  getProductName,
  getProductSource,
  getProductUrl,
  getSku,
  isLowestPriceSeen,
} from '@zalora/doraemon-ts/lib/utils/ZDTPriceDrop';
import { CONTENT_TYPE } from 'constants/contentful';
import { EXPLORE, FOR_YOU } from 'constants/segments';
import { ENV_TYPE_DEVICE, PROMOTION_EVENT_TYPE, TRACKING } from 'constants/tracking';
import {
  TrackingCmsPageData,
  TrackingPromotionData,
  PromotionItem,
  TrackingArrayPromotionData,
} from 'types/api/segment/tracking';
import { MediaWithPlacement } from 'types/post-types/PostTypes';
import { EmptyStateType } from 'types/price-drop';
import { getCurrencyCode } from 'utils/money';
import { getConfigSkuBySimpleSku, getCountrySkuByConfigSku } from 'utils/product';
import { isMobileScreenSize } from 'utils/screen-size';
import * as SegmentTracking from 'utils/segment-analytic';
import { capitalizeFirstLetter } from 'utils/tracking';
import { persistPromotionClickedPromotionName } from 'utils/tracking/homepage';

let trackedPromotions: MediaWithPlacement[] = [];
const VIEW_IMAGE_DEBOUNCE_TIME = 500;

const viewedPromotions: MediaWithPlacement[] = [];

let viewImageTimer: Nullable<ReturnType<typeof setTimeout>> = null;

export interface RawPromotionData {
  postType: string[];
  promotions: RawPromotion[];
}

export interface RawPromotion {
  promotion_id: string;
  promotion_creative: string;
  promotion_name?: string;
  promotion_position: string | null;
  promotion_vendor_source: string;
  promotion_url?: string;
}

export interface Promotion {
  id: string;
  creative: string;
  position: string | null;
  name?: string;
  vendorSource: string;
  postType: CONTENT_TYPE;
  navLink?: string;
}

export interface PromotionalEventData extends HompageSegmentTrackingData {
  post_type: string | null;
  promotions: RawPromotion[];
}

export interface HompageSegmentTrackingData {
  source_segment: string | null;
  source_page_type: string;
  list_id: string | null;
  vendor_source: string | null;
}

export const trackingHomepageSegmentViewed = (segmentId: string) => {
  if (!segmentId) {
    return;
  }

  SegmentTracking.track('Homepage Segment Viewed', {
    source_page_type: window?.trackingData?.source_page_type,
    source_segment: capitalizeFirstLetter(segmentId === EXPLORE ? FOR_YOU : segmentId),
    platform_source: isMobileScreenSize()
      ? ENV_TYPE_DEVICE.ENV_TYPE_MOBILE
      : ENV_TYPE_DEVICE.ENV_TYPE_DESKTOP,
  });
};

const sendTrackingPromotionView = (
  activeSegment: string,
  cmsContentTypeName: string,
  cmsPageTitle: string,
) => {
  if (viewImageTimer) {
    clearTimeout(viewImageTimer);
  }

  viewImageTimer = setTimeout(() => {
    trackPostTypeImageArrayByType(
      viewedPromotions,
      PROMOTION_EVENT_TYPE.ARRAY_PROMOTION_VIEWED,
      activeSegment,
      cmsContentTypeName,
      cmsPageTitle,
    );
    viewedPromotions.length = 0;
  }, VIEW_IMAGE_DEBOUNCE_TIME);
};

export const sendAllTrackingViewExisted = (trackCmsPageData: TrackingCmsPageData) => {
  if (!trackCmsPageData) {
    return;
  }

  const { title = '', contentTypeName = '', activeSegment = '' } = trackCmsPageData;
  const hasViewedStatic = viewedPromotions.length !== 0;

  // Tracking promotion view when static images load
  if (!hasViewedStatic) {
    return;
  }

  sendTrackingPromotionView(activeSegment, contentTypeName, title);
};

// we still using the old tracking version, hence need to separate it to 2 logics
export const onPromotionView = (
  trackCmsPageData: TrackingCmsPageData,
  image: MediaWithPlacement,
) => {
  if (isImageAlreadyAdd(image, trackedPromotions)) {
    return;
  }

  const { title = '', contentTypeName = '', activeSegment } = trackCmsPageData;

  trackPostTypeImageByType(
    image,
    PROMOTION_EVENT_TYPE.PROMOTION_VIEWED,
    activeSegment,
    contentTypeName,
    title,
  );

  if (isImageAlreadyAdd(image, viewedPromotions)) {
    return;
  }

  viewedPromotions.push(image);
  sendAllTrackingViewExisted(trackCmsPageData);
};

/**
 * Do click-tracking for a contentful image
 * @param {*} media Likely be `@zalora/post-types` MediaElement type
 * @returns void
 */
export const onClickTrackingImageSegment = (
  trackCmsPageData: TrackingCmsPageData,
  image: MediaWithPlacement,
) => {
  const { title = '', contentTypeName = '', activeSegment } = trackCmsPageData;

  sendAllTrackingViewExisted(trackCmsPageData);

  if (!image) {
    return;
  }

  trackPostTypeImageByType(
    image,
    PROMOTION_EVENT_TYPE.PROMOTION_CLICKED,
    activeSegment,
    contentTypeName,
    title,
  );

  trackPostTypeImageArrayByType(
    [image],
    PROMOTION_EVENT_TYPE.ARRAY_PROMOTION_CLICKED,
    activeSegment,
    contentTypeName,
    title,
  );

  if (image.internalPromotionalName) {
    persistPromotionClickedPromotionName(image.internalPromotionalName);
  }
};

const isImageAlreadyAdd = (image: MediaWithPlacement, arrayImage: MediaWithPlacement[]) => {
  return arrayImage.some((elem) => {
    return image.entryId === elem.entryId;
  });
};

const trackPostTypeImageByType = (
  image: MediaWithPlacement,
  action: string,
  activeKey: string,
  cmsContentTypeName: string,
  cmsPageTitle: string,
) => {
  const isStaticPage = cmsContentTypeName === 'staticPage';

  const segmentData: TrackingPromotionData = {
    source_segment: capitalizeFirstLetter(activeKey),
    source_page_type: 'Homepage',
    list_id: isStaticPage ? cmsPageTitle : getListId(activeKey),
    promotion_creative: image.mediaUrl,
    promotion_name: image.internalPromotionalName,
    promotion_position: image.xyPosition,
    promotion_vendor_source: TRACKING.STATIC_DATA_SOURCE,
    promotion_post_type: image.postType,
    promotion_post_shelf_rank: `${image.postShelfRank}`,
    promotion_post_title: image.postTitle,
    promotion_post_internal_name: image.postInternalName,
    promotion_url: image.navLink,
    platform_source: isMobileScreenSize()
      ? ENV_TYPE_DEVICE.ENV_TYPE_MOBILE
      : ENV_TYPE_DEVICE.ENV_TYPE_DESKTOP,
  };

  if (action === PROMOTION_EVENT_TYPE.PROMOTION_VIEWED) {
    SegmentTracking.track(PROMOTION_EVENT_TYPE.PROMOTION_VIEWED, { ...segmentData });
    trackedPromotions = [...trackedPromotions, image];
  }

  if (action === PROMOTION_EVENT_TYPE.PROMOTION_CLICKED) {
    SegmentTracking.track(PROMOTION_EVENT_TYPE.PROMOTION_CLICKED, { ...segmentData });
  }
};

const trackPostTypeImageArrayByType = (
  images: MediaWithPlacement[],
  action: string,
  activeKey: string,
  cmsContentTypeName: string,
  cmsPageTitle: string,
) => {
  const isStaticPage = cmsContentTypeName === 'staticPage';
  const segmentData: TrackingArrayPromotionData = {
    source_segment: capitalizeFirstLetter(activeKey),
    source_page_type: 'Homepage',
    list_id: isStaticPage ? cmsPageTitle : getListId(activeKey),
    platform_source: isMobileScreenSize()
      ? ENV_TYPE_DEVICE.ENV_TYPE_MOBILE
      : ENV_TYPE_DEVICE.ENV_TYPE_DESKTOP,
    promotions: [] as PromotionItem[],
  };

  segmentData.promotions = images.map((banner: MediaWithPlacement) => {
    return {
      promotion_id: banner.placement === TRACKING.STATIC ? banner.entryId : banner.placement,
      promotion_position: banner.xyPosition,
      promotion_name: banner.internalPromotionalName,
      promotion_creative: banner.mediaUrl,
      promotion_url: banner.navLink,
      promotion_vender_source: TRACKING.STATIC_DATA_SOURCE,
      promotion_post_type: banner.postType,
    };
  });

  if (action === PROMOTION_EVENT_TYPE.ARRAY_PROMOTION_VIEWED) {
    SegmentTracking.track(PROMOTION_EVENT_TYPE.ARRAY_PROMOTION_VIEWED, { ...segmentData });
  }

  if (action === PROMOTION_EVENT_TYPE.ARRAY_PROMOTION_CLICKED) {
    SegmentTracking.track(PROMOTION_EVENT_TYPE.ARRAY_PROMOTION_CLICKED, { ...segmentData });
  }
};

const getListId = (segment: string) => {
  switch (segment) {
    case EXPLORE: {
      return FOR_YOU;
    }

    default: {
      return capitalizeFirstLetter(segment);
    }
  }
};

/**
 * tracking Static Banner of HTML posttype
 *
 * NOTE: trackingData should be JSON object as a string with keys such as
 * imageUrl, position, promotionName, exitUrl for both impression and click
 * events Example tracking Data is included in URL
 * zalora://my/urlc/product/?catalogtype=Main&specialKey=all&special-url=all-products&trackingData={"imageUrl":"https://static-sg.zacdn.com/cms/2022/SP/1212/APP/HEADER1.jpg","promotionName":"1212sale-hero-main-1","position":"1-1"}
 */
export const trackingStaticBannerHTMLContentful = (id: string, segment: string) => {
  if (typeof window.IntersectionObserver !== 'function') {
    return;
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(({ target, isIntersecting }) => {
      if (isIntersecting) {
        observer.unobserve(target);

        const hrefAttr = target && target.getAttribute('href');

        const urlParams = hrefAttr && new URLSearchParams(hrefAttr);
        const trackingDataString = urlParams && urlParams.get('trackingData');

        if (trackingDataString) {
          try {
            const trackingData = JSON.parse(decodeURIComponent(trackingDataString));

            const promotion = {
              id: '',
              creative: trackingData.imageUrl,
              name: trackingData.promotionName,
              position: trackingData.position,
              navLink: hrefAttr,
              vendorSource: 'Static',
              postType: CONTENT_TYPE.HTML,
            };

            const data = getPromotionalEventData([promotion], segment);

            SegmentTracking.track(PROMOTION_EVENT_TYPE.PROMOTION_VIEWED, { ...data });

            target.addEventListener(
              'click',
              () => {
                SegmentTracking.track(PROMOTION_EVENT_TYPE.PROMOTION_CLICKED, { ...data });
              },
              false,
            );
          } catch (error) {}
        }
      }
    });
  });

  const domCMSPage = document.getElementById(id)?.querySelector('iframe')?.contentDocument?.body;

  const listPromotionPage = domCMSPage && domCMSPage.querySelectorAll('a');

  listPromotionPage &&
    listPromotionPage.forEach((image) => {
      const hrefAttr = image && image.getAttribute('href');

      if (hrefAttr && hrefAttr.indexOf('trackingData=') !== -1) {
        image && observer.observe(image);
      }
    });
};

const getPromotionalEventData = (
  rawPromotions: Promotion[],
  segment: string,
): PromotionalEventData => {
  const { postType, promotions } =
    rawPromotions.reduce(
      (prev: RawPromotionData, promotion: Promotion) => {
        const data = {
          promotion_id: promotion.id,
          promotion_creative: promotion.creative,
          promotion_name: promotion.name,
          promotion_position: promotion.position,
          promotion_vendor_source: promotion.vendorSource,
          promotion_url: promotion.navLink,
        };

        prev?.postType?.push(promotion.postType);
        prev?.promotions?.push(data);

        return prev;
      },
      { postType: [], promotions: [] },
    ) || {};

  return {
    source_segment: segment,
    source_page_type: 'Segment',
    list_id: segment,
    vendor_source: '',
    post_type: postType?.toString?.() || null,
    promotions,
  };
};

/**
 * ##########
 * Price drop 📉
 * ##########
 */

/**
 * User viewed items with price drop at item level
 */
export const trackPriceDropTrackerViewedItemLevel = ({
  product,
  sourceSegment,
  modulePosition,
}: {
  product: ZDTPriceDrop.Product;
  sourceSegment: string;
  modulePosition: number;
}) => {
  const productSku = getSku(product);

  const payload: SegmentTracking.SegmentTrackEventPayload<'Price Drop Tracker Viewed Item Level'> =
    {
      brand: getBrandName(product),
      category: getBreadcrumbs(product)[0],
      countrySKU: getCountrySkuByConfigSku(productSku),
      name: getProductName(product),
      sku: productSku,
      marketplace: product.SellerName === 'ZALORA',
      price_original: getOriginalPrice(product),
      final_price: getCurrentPrice(product),
      url: getProductUrl(product),
      image_url: getImageUrl(product),
      currency: getCurrencyCode(),
      parentSKU: getConfigSkuBySimpleSku(productSku),
      source_segment: sourceSegment,
      list_id: sourceSegment,
      module_position: `${modulePosition}`,
      best_price_tag: isLowestPriceSeen(product),
      price_dropped_value: getPriceDrop(product),
      price_when_added: getInitialPrice(product),
      item_source: getProductSource(product),
    };

  SegmentTracking.track('Price Drop Tracker Viewed Item Level', payload, {
    shouldIncludeSourcePageType: true,
  });
};

/**
 * User clicked items with price drop at item level
 */
export const trackPriceDropTrackerClickedItemLevel = ({
  modulePosition,
  product,
  sourceSegment,
}: {
  modulePosition: number;
  product: ZDTPriceDrop.Product;
  sourceSegment: string;
}) => {
  const productSku = getSku(product);

  const payload: SegmentTracking.SegmentTrackEventPayload<'Price Drop Tracker Clicked Item Level'> =
    {
      brand: getBrandName(product),
      category: getBreadcrumbs(product)[0],
      countrySKU: getCountrySkuByConfigSku(productSku),
      name: getProductName(product),
      sku: productSku,
      marketplace: product.SellerName === 'ZALORA',
      price_original: getOriginalPrice(product),
      final_price: getCurrentPrice(product),
      url: getProductUrl(product),
      image_url: getImageUrl(product),
      currency: getCurrencyCode(),
      parentSKU: getConfigSkuBySimpleSku(productSku),
      source_segment: sourceSegment,
      list_id: sourceSegment,
      module_position: `${modulePosition}`,
      best_price_tag: isLowestPriceSeen(product),
      price_dropped_value: getPriceDrop(product),
      price_when_added: getInitialPrice(product),
      item_source: getProductSource(product),
    };

  SegmentTracking.track('Price Drop Tracker Clicked Item Level', payload, {
    shouldIncludeSourcePageType: true,
  });
};

/**
 * User viewed module on Homepage & user does not have any price dropped items.
 * There are 2 scenarios:
 *   1. User does not have any items in their cart/wishlist
 *   2. User does not have any items that have price drop for that day
 */
export const trackPriceDropTrackerEmptyStateViewed = ({
  emptyStateType,
  modulePosition,
  sourceSegment,
}: {
  emptyStateType: EmptyStateType;
  modulePosition: number;
  sourceSegment: string;
}) => {
  const payload: SegmentTracking.SegmentTrackEventPayload<'Price Drop Tracker Empty State Viewed'> =
    {
      source_segment: sourceSegment,
      list_id: sourceSegment,
      module_position: `${modulePosition}`,
      empty_state_type: emptyStateType,
    };

  SegmentTracking.track('Price Drop Tracker Empty State Viewed', payload, {
    shouldIncludeSourcePageType: true,
  });
};

/**
 * User clicks on CTA to enter a catalog if user does not have any price dropped
 * items. There are 2 scenarios:
 *   1. User does not have any items in their cart/wishlist
 *   2. User does not have any items that have price drop for that day
 */
export const trackPriceDropTrackerEmptyStateClicked = ({
  emptyStateType,
  modulePosition,
  sourceSegment,
}: {
  emptyStateType: EmptyStateType;
  modulePosition: number;
  sourceSegment: string;
}) => {
  const payload: SegmentTracking.SegmentTrackEventPayload<'Price Drop Tracker Empty State Clicked'> =
    {
      source_segment: sourceSegment,
      list_id: sourceSegment,
      module_position: `${modulePosition}`,
      empty_state_type: emptyStateType,
    };

  SegmentTracking.track('Price Drop Tracker Empty State Clicked', payload, {
    shouldIncludeSourcePageType: true,
  });
};
