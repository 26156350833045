import { MediaType, ProductElement } from '@zalora/post-types/lib/common/types';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { fetchDataSourceAPI } from 'api/externalAPI';
import { parallelFetch } from 'hooks/api/usePromotion';
import { useTwilioUserTrait } from 'hooks/api/useTwilioUserTrait';
import useUser from 'hooks/api/useUser';
import { arrayToObject, camelCase } from 'services/contentful/helper';
import { useLandingPageStore, useLandingPageDispatch } from 'stores/landingPage';
import { URL_TYPE, ExternalData, MediaWithPlacement } from 'types/post-types/PostTypes';
import {
  ExternalSourceRecommendation,
  RawRecommendation,
} from 'types/post-types/RecommendationTypes';
import isServer from 'utils/is-server';
import {
  getExternalSourceData,
  getFormattedPrice,
  normalizeInternalMediaItem,
} from 'utils/post-type/helper';
import { isMobileScreenSize } from 'utils/screen-size';

const jslProductSponsored = {
  images: '.image_url',
  maxItems: 70,
  brand_name: '.brand',
  config_sku: '.sku',
  auto_scroll: false,
  description: '',
  product_name: '.name',
  tracking_url: '.url',
  current_price: '.price',
  previous_price: '.price_original',
  product_url: '.url',
  gender: '.gender',
};

export interface PostTypeWithProductProps {
  activeKey: string;
  post: RawRecommendation;
  renderedCallback?: () => void;
}

export interface FormattedDataRecFeed {
  items?: ProductElement[];
  typeOfRecFeed?: string;
  placement?: string;
  description?: string;
  dynTitle?: string;
  feedBanner?: MediaWithPlacement;
}

export const useRecFeedPostType = ({
  post,
  activeKey = '',
  renderedCallback,
}: PostTypeWithProductProps) => {
  const { removePostById, updateFetchedExternalData, setPromotions } = useLandingPageDispatch();
  const router = useRouter();
  const { entryId = '', enableSegmentAudienceFilter, segmentAudience, audienceBucket } = post || {};

  useTwilioUserTrait({
    enableSegmentAudienceFilter,
    segmentAudience,
    audienceBucket,
    callbackAction: () => removePostById(activeKey, entryId),
  });

  // To enhance performance, data prioritization starts with mobile devices.
  // However, on the server, device type detection isn't possible.
  // Therefore, if the code is executing on the server, we consider it as a mobile environment.
  const isMobile = isServer() || isMobileScreenSize();

  const { data: user } = useUser();

  const segmentStoreData = useLandingPageStore((state) => state?.contentfulData?.[activeKey]);
  const [formattedData, setFormattedData] = useState<Nullishable<FormattedDataRecFeed>>(null);

  const { dataSources, fetchedDataSources } = segmentStoreData;
  // we migrated data source from RR to DJ, so we'll use sourceConfigV2 instead of sourceConfig
  const sourceConfig = post.sourceConfigV2 || {};
  const fetchedData = fetchedDataSources?.[sourceConfig.source];

  const fetchDataSource = async () => {
    const idSource = sourceConfig.source;

    if (!dataSources?.[idSource]) {
      return {
        [idSource]: { isSuccess: false, idSource, fetched: true },
      };
    }

    if (fetchedDataSources?.[idSource]?.fetched) {
      return { [idSource]: fetchedDataSources?.[idSource] };
    }

    const fetchedData = await fetchDataSourceAPI({
      sourceConfigOfPost: [dataSources?.[idSource]],
      activeSegment: activeKey,
      zuid: user?.Zuid,
      locale: router.locale,
    });

    const fetchedSources = arrayToObject(fetchedData, 'idSource');

    if (!fetchedData?.[0]?.response) {
      return {
        [idSource]: { isSuccess: false, idSource, fetched: true },
      };
    }

    return fetchedSources;
  };

  const updateStoreFetchedSource = (fetchedSources: { [k: string]: ExternalData }) => {
    updateFetchedExternalData(activeKey, fetchedSources);
  };

  const fetchPromotions = async (fetchedSources: { [k: string]: ExternalData }) => {
    const { response } = Object.values(fetchedSources)?.[0];

    if (response && response.placements) {
      const { placements } = response;
      const skus: string[] = [];

      placements.forEach((placement) => {
        if (!('recommendedProducts' in placement && placement.recommendedProducts.length >= 0)) {
          return;
        }

        placement.recommendedProducts.forEach((item) => {
          if (!item.id) {
            return;
          }

          skus.push(item.id);
        });
      });

      const { Promotions } = await parallelFetch(skus);

      setPromotions(Promotions);
    }
  };

  // after update fetchedData
  useEffect(() => {
    if (!fetchedData?.fetched) {
      return;
    }

    const formattedData = normalizeJslRecFeed(fetchedData, post, isMobile);

    if (renderedCallback) {
      renderedCallback();
    }

    if (!formattedData?.items?.length) {
      removePostById(activeKey, post?.entryId || '');

      return;
    }

    setFormattedData(formattedData);
  }, [activeKey, isMobile, post, removePostById, router.locale, renderedCallback, fetchedData]);

  return {
    fetchDataSource,
    isMobile,
    updateStoreFetchedSource,
    fetchPromotions,
    formattedData,
  };
};

export const normalizeJslRecFeed = (
  sourceFetched: ExternalData,
  post: RawRecommendation,
  isMobile: boolean,
) => {
  const sourceConfig = post.sourceConfigV2 || {};
  const sourceConfigData = sourceConfig.data || {};
  const {
    items = [],
    dynTitle = '',
    ...externalMedia
  } = getExternalSourceData<{
    items: unknown[];
    placement: string;
    dynTitle?: string;
  }>(sourceConfigData, sourceFetched.response);

  const jsl = camelCase(getJsl(sourceFetched.urlType) || sourceConfigData);

  const maxItems = 'maxItems' in jsl ? +jsl.maxItems : 0;

  const formattedItems: ProductElement[] = truncatedArray(items, maxItems || items.length)?.map(
    (item, index) => {
      const formatItem = getExternalSourceData<ExternalSourceRecommendation>(
        { ...jsl, formattedUrl: '.productURL' },
        item,
      );
      const {
        previousPrice = 0,
        currentPrice = 0,
        configSku,
        images,
        brandName,
        productName,
        trackingUrl,
        productUrl,
        formattedUrl,
      } = formatItem;
      const formattedPrice = getFormattedPrice({
        previousPrice,
        currentPrice,
      });

      return {
        brand: brandName,
        imageURL: images,
        sku: configSku,
        name: productName,
        productURL: formattedUrl,
        clickURL: productUrl || `/p/${configSku}`,
        countrySKU: configSku,
        position: index,

        trackUrl: trackingUrl,
        onSale: currentPrice < previousPrice,
        price: {
          ...formattedPrice,
          priceNumber: currentPrice,
          priceOriginalNumber: previousPrice,
        },
      };
    },
  );
  const feedBanner = post.feedBanner
    ? normalizeInternalMediaItem(post.feedBanner, isMobile)
    : undefined;

  return {
    ...externalMedia,
    dynTitle,
    items: formattedItems,
    typeOfRecFeed: sourceFetched.urlType,
    feedBanner: {
      ...(feedBanner ? { ...feedBanner } : {}),
      mediaUrl: feedBanner?.mediaData.mediaUrl || '',
      navLink: feedBanner?.navLink || '',
      type: feedBanner?.type || MediaType.Image,
    },
  };
};

export const getJsl = (type?: string) => {
  if (!type) {
    return '';
  }

  switch (type) {
    case URL_TYPE.SPONSORED: {
      return jslProductSponsored;
    }

    default: {
      return '';
    }
  }
};

export const truncatedArray = <T extends unknown[]>(array?: T, n?: number) => {
  return (array?.slice(0, n) || []) as T;
};
