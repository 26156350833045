import { ArrowLeftIcon } from '@zalora/zui-icons';
import React from 'react';

const LeftButton: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
  <ArrowLeftIcon
    {...props}
    width={24}
    height={24}
  />
);

export default LeftButton;
