import { Banner as BaseBanner } from '@zalora/post-types';
import { BannerVariations, HorizontalScale } from '@zalora/post-types/lib/Banner/types';
import { BleedType, LoadingState } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import ObserverWrapper from 'components/Contentful/ObserverWrapper';
import { SkeletonBanner } from 'components/skeleton/SkeletonBanner';
import { CONTENT_TYPE } from 'constants/contentful';
import {
  ANIMATION_POST_TYPE_CONFIG,
  DEFAULT_VIEWPORT_VISIBILITY_ROOT_MARGIN,
  DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD,
} from 'constants/post-types';
import { useMediaPostType } from 'hooks/segment/usePostType';
import { onClickTrackingImageSegment, onPromotionView } from 'pages/segment/[segment].tracking';
import { RefinedBannerProps } from 'types/post-types/BannerTypes';
import {
  getBannerType,
  getDateTimePostType,
  getScrollScaleByDevice,
  getIsCustomOffsetBanner,
  getRatioPost,
  isMediaAvailable,
} from 'utils/post-type/helper';
import LeftButton from './LeftButton';
import MediaLink from './MediaLink';
import RightButton from './RightButton';
import '@zalora/post-types/styles/banner.css';

const carouselOption = {
  LeftButton,
  RightButton,
};

const Banner: React.FC<RefinedBannerProps> = ({
  dataPosition,
  className,
  post,
  activeKey,
  ratio,
  isShowPaging,
  styles,
  ...props
}) => {
  const { displayMedia, trackCmsPageData, isMobile, isLoading, onImpression } = useMediaPostType({
    activeKey: activeKey || '',
    post,
    ...props,
  });

  const shouldDisplayLoadingSkeleton = isLoading || !isMediaAvailable(displayMedia);

  const { bannerType, horizontalScrollScale } = useMemo(() => {
    const {
      horizontalScrollScale: horizontalScrollScaleMob = '',
      horizontalScrollScaleDesktop = '',
      autoScroll,
    } = post;

    const availableDisplayMedia = displayMedia?.length || 0;
    const scrollScaleByDevice = isMobile ? horizontalScrollScaleMob : horizontalScrollScaleDesktop;
    const horizontalScrollScale = getScrollScaleByDevice({
      isMobile,
      horizontalScrollScale: scrollScaleByDevice as HorizontalScale,
      availableDisplayMedia,
    });
    const isCustomOffsetBanner = getIsCustomOffsetBanner(horizontalScrollScale);

    const bannerType = getBannerType(displayMedia, autoScroll, isCustomOffsetBanner);

    return { bannerType, horizontalScrollScale };
  }, [displayMedia, isMobile, post]);

  const layout = useMemo(() => {
    const {
      fontColor = '',
      title = '',
      description = '',
      ctaNavigationLink = '',
      ctaLabel = '',
      bleedType = BleedType.Half,
      internalName,
      countdownTimerStart,
      countdownTimerEnd,
      countdownTimerText = '',
      postShelfRank,
    } = post;

    const ratio = getRatioPost(post.media);

    return (
      <>
        <SkeletonBanner
          className={clsx({
            hidden: !shouldDisplayLoadingSkeleton,
            'mb-6 tablet:mb-8 desktop:mb-12': shouldDisplayLoadingSkeleton,
            'px-4': bleedType === BleedType.Half,
            'px-1': bleedType === BleedType.Full,
          })}
          ratio={ratio}
          column={+(horizontalScrollScale || HorizontalScale.NoScale)}
          hasTitle={!!title}
        />
        <div
          className={clsx({
            hidden: shouldDisplayLoadingSkeleton,
            'mb-6 tablet:mb-8 desktop:mb-12': !shouldDisplayLoadingSkeleton,
          })}
        >
          <BaseBanner
            className={clsx(
              fontColor ? (ANIMATION_POST_TYPE_CONFIG.CUSTOM_STYLE_CLASS_NAME as string) : '',
            )}
            internalName={internalName}
            title={title}
            description={description}
            media={displayMedia}
            countdownTimerText={countdownTimerText}
            countdownTimerStart={countdownTimerStart}
            countdownTimerEnd={countdownTimerEnd}
            ctaLabel={ctaLabel}
            ctaLink={ctaNavigationLink}
            getTime={getDateTimePostType}
            ratio={ratio}
            isShowPaging={isShowPaging}
            MediaLink={(props) => (
              <MediaLink
                onClick={() => {
                  onClickTrackingImageSegment(trackCmsPageData, {
                    ...props.data,
                    xyPosition: `${dataPosition}-${props.data.position + 1}`,
                    postType: CONTENT_TYPE.BANNER,
                    postShelfRank,
                    postTitle: title,
                    postInternalName: internalName,
                  });
                }}
                {...props}
              />
            )}
            bannerType={bannerType || BannerVariations.Fixed}
            horizontalScale={horizontalScrollScale}
            loadingState={LoadingState.Loaded}
            carouselOption={carouselOption}
            desktopMediaWrapper={!isMobile} // flag to identify the platform and switch the wrapper
            bleedType={bleedType || BleedType.Half}
            onImpression={(image) => {
              onPromotionView(trackCmsPageData, {
                ...image,
                xyPosition: `${dataPosition}-${+image.position + 1}`,
                postType: CONTENT_TYPE.BANNER,
                postShelfRank,
                postTitle: title,
                postInternalName: internalName,
              });
            }}
          />
        </div>
      </>
    );
  }, [
    post,
    shouldDisplayLoadingSkeleton,
    horizontalScrollScale,
    displayMedia,
    isShowPaging,
    bannerType,
    isMobile,
    trackCmsPageData,
    dataPosition,
  ]);

  return (
    <div
      className={className}
      style={styles}
    >
      <ObserverWrapper
        rootMargin={DEFAULT_VIEWPORT_VISIBILITY_ROOT_MARGIN}
        threshold={DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD}
        onEvent={onImpression}
      >
        {layout}
      </ObserverWrapper>
    </div>
  );
};

export default memo(Banner);
