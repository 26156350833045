import { ProductElement } from '@zalora/post-types/lib/common/types';
import React, { FC, memo } from 'react';
import { Price } from 'components/Price';
import { useLandingPageStore } from 'stores/landingPage';
import { selectPromotions } from 'stores/landingPage/selectors';

interface Props {
  product: ProductElement;
}

const RecommendationProductPrice: FC<Props> = ({ product }) => {
  const promotions = useLandingPageStore(selectPromotions);

  if (!product.price) {
    return null;
  }

  const {
    sku = '',
    price: { priceOriginalNumber = 0, priceNumber = 0 },
  } = product;

  const specialPrice = priceNumber ? priceNumber : NaN;

  const firstPromo = promotions?.[sku]?.[0];
  const effectivePrice = firstPromo ? firstPromo.EffectivePrice : 0;
  const effectivePriceFormatted = firstPromo ? firstPromo.EffectivePriceFormatted : '';

  const hasVoucher = !!effectivePriceFormatted;
  const discountedPrice = {
    price: hasVoucher ? effectivePrice : specialPrice,
    hasVoucher,
  };

  return (
    <Price
      originalPrice={priceOriginalNumber}
      discountedPrice={discountedPrice}
    />
  );
};

export default memo(RecommendationProductPrice);
