import currencyConfiguration from 'constants/money';
import { Country } from 'constants/ventures';
import { getEnvConfiguration } from './env-configuration';

export interface FormatMoneyOptions {
  countryCode?: Country;
  shouldAddCurrencySymbol?: boolean;
  shouldAddSign?: boolean;
  shouldAddThousandsSeparators?: boolean;
  shouldRemoveFractionalNumbers?: boolean;
  shouldRemoveSpace?: boolean;
  shouldKeepNonZeroDecimalsOnly?: boolean;
}

export function formatMoney(
  money: string | number | null | undefined,
  as: 'number',
  formatOptions?: FormatMoneyOptions,
): number;

export function formatMoney(
  money: string | number | null | undefined,
  as: 'string',
  formatOptions?: FormatMoneyOptions,
): string;

export function formatMoney(
  money: string | number | null | undefined,
  as: 'string' | 'number',
  formatOptions?: FormatMoneyOptions,
): string | number {
  if (
    (typeof money === 'string' && money === '') ||
    typeof money === 'undefined' ||
    money === null
  ) {
    return as === 'string' ? 'NaN' : NaN;
  }

  const {
    countryCode = getEnvConfiguration('CC'),
    shouldAddCurrencySymbol = true,
    shouldAddThousandsSeparators = true,
    shouldRemoveFractionalNumbers,
    shouldAddSign = false,
    shouldRemoveSpace = false,
    shouldKeepNonZeroDecimalsOnly = false,
  } = formatOptions || {};

  const config = currencyConfiguration[countryCode as Country];

  const { Decimals, DisplayFormat, DecimalsSeparator, ThousandSeparator } = config;
  let floatString = typeof money === 'string' ? money : money.toFixed(Decimals);

  // Remove thousands separators, these are always followed by 3 digits
  floatString = floatString.replace(new RegExp(`\\${ThousandSeparator}(\\d{3})`, 'g'), '$1');

  // Replace non-default decimal separator at the end of the string with a `.`
  // to create a floating point number. This allows us to later create a real
  // float used for parsing.
  if (DecimalsSeparator !== '.') {
    floatString = floatString.replace(new RegExp(`\\${DecimalsSeparator}(\\d{2})$`, 'g'), '.$1');
  }

  // Trim remaining non-numeric characters from the front and back of the string
  // not remove the first `-` character
  floatString = floatString.replace(/(^[^0-9-]+)|([^0-9]+$)/g, '');

  if (floatString === '') {
    return as === 'string' ? 'NaN' : NaN;
  }

  // Use `Number` instead of `parseFloat` to avoid parsing strings like
  // "1000;30" as a valid number.
  const parsed = Number(floatString);

  floatString = parseNumber({
    value: parsed,
    options: { shouldKeepNonZeroDecimalsOnly, shouldRemoveFractionalNumbers },
    decimalsConfig: Decimals,
  });

  if (as === 'number') {
    return parseFloat(floatString);
  }

  if (shouldAddThousandsSeparators) {
    floatString = floatString.replace(/\B(?=(\d{3})+(?!\d))/g, ThousandSeparator);
  }

  if (shouldAddCurrencySymbol) {
    floatString = `${DisplayFormat.replace('%s', floatString)}`;
  }

  if (shouldAddSign && !isNaN(parsed)) {
    const signChar = parsed < 0 ? '-' : '+';

    floatString = `${signChar} ${floatString}`;
  }

  if (shouldRemoveSpace) {
    floatString = floatString.replace(/\s/g, '');
  }

  return floatString;
}

export const getCurrencyCode = () => {
  const countryCode = getEnvConfiguration('CC') as Country;
  const { Iso = '' } = currencyConfiguration[countryCode] || {};

  return Iso;
};

const parseNumber = ({
  value,
  options,
  decimalsConfig,
}: {
  value: number;
  options: Pick<
    FormatMoneyOptions,
    'shouldRemoveFractionalNumbers' | 'shouldKeepNonZeroDecimalsOnly'
  >;
  decimalsConfig: number;
}) => {
  const { shouldKeepNonZeroDecimalsOnly, shouldRemoveFractionalNumbers } = options;
  const absoluteValue = Math.abs(value);

  if (shouldRemoveFractionalNumbers) {
    return absoluteValue.toFixed(0);
  }

  if (shouldKeepNonZeroDecimalsOnly) {
    return absoluteValue % 1 === 0
      ? absoluteValue.toFixed(0)
      : absoluteValue.toFixed(decimalsConfig);
  }

  return absoluteValue.toFixed(decimalsConfig);
};
