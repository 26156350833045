import React from 'react';

interface MediaLinkProps {
  to: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  data?: { placement?: string; entryId?: string };
}

const MediaLink: React.FC<MediaLinkProps> = ({ children, to, className, data, onClick }) => {
  if (!to) {
    return <span className={className}>{children}</span>;
  }

  return (
    <a
      className={className}
      href={to}
      data-placement-id={data?.placement || ''}
      data-entry-id={data?.entryId}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default MediaLink;
