import { Recommendation as BaseRecommendation } from '@zalora/post-types';
import { BleedType, LoadingState, ProductElement } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { FC, lazy, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { mutate } from 'swr';
import AddToBagButton from 'components/AddToBagButton';
import AddToBagModalConfig from 'components/AddToBagButton/AddToBagModal.config';
import WishlistButton from 'components/WishlistButton';
import { SkeletonRecommendation } from 'components/skeleton';
import { ANIMATION_POST_TYPE_CONFIG } from 'constants/post-types';
import { ENV_TYPE_DEVICE } from 'constants/tracking';
import { useRecFeedPostType } from 'hooks/segment/useRecFeedPostType';
import useFetchInViewport from 'hooks/useFetchInViewport';
import { useUiDispatch } from 'stores/ui';
import { ApiResponse } from 'types/api/ApiResponse';
import {
  ProductListOption,
  RefinedRecommendationProps,
} from 'types/post-types/RecommendationTypes';
import { ProductElementPostType } from 'types/trackingPayload/HomePageTrackingPayload';
import LeftButton from './LeftButton';
import ProductLink from './ProductLink';
import {
  ProductElementTracking,
  SEGMENT_CLICK_EVENT,
  trackingOnRecFeedProductAddedToBag,
  trackingOnRecFeedProductAddedToWishlist,
  triggerSegmentTracking,
  onProductImpression,
} from './Recommendation.tracking';
import ProductPrice from './RecommendationProductPrice';
import RightButton from './RightButton';
import '@zalora/post-types/styles/recommendation.css';

const AddToBagModal = lazy(() => import('components/AddToBagButton/AddToBagModal'));

const carouselOption = {
  LeftButton,
  RightButton,
};

const Recommendation: FC<RefinedRecommendationProps> = ({
  dataPosition,
  className,
  post,
  activeKey,
  styles,
  hasBackground,
  recFeedRank,
  ...props
}) => {
  const { t } = useTranslation();
  const { fetchDataSource, updateStoreFetchedSource, fetchPromotions, formattedData, isMobile } =
    useRecFeedPostType({
      activeKey,
      post,
      ...props,
    });

  const {
    fontColor = '',
    title = '',
    internalPromotionName = '',
    description = '',
    ctaNavigationLink = '',
    ctaLabel = '',
    bleedType = BleedType.Half,
    sourceConfigV2: sourceConfig,
  } = post;

  const recRef = useRef<HTMLDivElement>(null);
  const uiDispatch = useUiDispatch();
  const recFeedModulePosition = recFeedRank ? recFeedRank : 0;
  const moduleTitle = title;

  useFetchInViewport(
    recRef,
    `/data_source_${activeKey}_${sourceConfig?.source}`,
    () => fetchDataSource(),
    //OnSuccess
    (data) => {
      updateStoreFetchedSource(data);
      fetchPromotions(data);
    },
  );

  const handleDataUpdate = useCallback(() => {
    mutate(`/data_source_${activeKey}_${sourceConfig?.source}`, undefined, true);
  }, [activeKey, sourceConfig?.source]);

  useEffect(() => {
    handleDataUpdate();
  }, [handleDataUpdate]);

  const onClickAddToWishListIcon = useCallback(
    ({
      isAdded,
      product,
      recFeedModulePosition,
      moduleTitle,
    }: {
      product: ApiResponse<ProductElementPostType>;
      isAdded: boolean;
      recFeedModulePosition: number;
      moduleTitle: string;
    }) => {
      uiDispatch.addToast({
        message: isAdded
          ? t('Added to wishlist')
          : t('Product successfully removed from your wishlist'),
      });

      trackingOnRecFeedProductAddedToWishlist({
        configSku: product.data.sku || '',
        modulePosition: dataPosition?.toString(),
        vendor: 'datajet',
        id: '',
        xPosition: product.data.position.toString(),
        listId: 'Homepage page',
        recFeedModulePosition,
        moduleTitle,
        promotionInternalName: internalPromotionName || '',
      });
    },

    [dataPosition, internalPromotionName, t, uiDispatch],
  );

  const openAddToBagModal = useCallback(
    (
      skuOrSlug: string,
      productPosition: number,
      recFeedModulePosition: number,
      moduleTitle: string,
    ) => {
      uiDispatch.showModal({
        key: 'add-to-bag-modal',
        config: AddToBagModalConfig,
        ModalDialogPage: () => (
          <AddToBagModal
            selectedSkuOrSlug={skuOrSlug}
            productPosition={productPosition}
            onSuccessAddToBag={({ sku, productPosition }) => {
              uiDispatch.addToast({ message: t('Added to bag successfully') });

              trackingOnRecFeedProductAddedToBag({
                configSku: sku || '',
                modulePosition: dataPosition?.toString(),
                vendor: 'datajet',
                id: '',
                xPosition: productPosition?.toString() || '',
                listId: 'Homepage page',
                recFeedModulePosition,
                moduleTitle,
                promotionInternalName: internalPromotionName || '',
              });
            }}
          />
        ),
      });
    },
    [dataPosition, internalPromotionName, t, uiDispatch],
  );

  const productListOption = useMemo(
    () => ({
      loadingState: LoadingState.Loaded,
      products: formattedData?.items,

      AddToWishListIcon: (product: ApiResponse<ProductElementPostType>) => {
        return (
          <WishlistButton
            sku={product.data.sku || ''}
            onToggle={(isAdded) =>
              onClickAddToWishListIcon({ product, isAdded, recFeedModulePosition, moduleTitle })
            }
            className="m-0 !h-5 !w-7 p-0"
          />
        );
      },

      AddToBagComponent: ({ data }: { data: { sku: string; position: number } }) => {
        return (
          <AddToBagButton
            variant="outline"
            className="mt-auto"
            onClick={() =>
              openAddToBagModal(data.sku, data.position, recFeedModulePosition, moduleTitle)
            }
          />
        );
      },

      renderPrice:
        post.type === 'sponsored-ads'
          ? undefined
          : (product: ProductElement) => <ProductPrice product={product} />,

      onImpression: (data: ProductElementTracking) => {
        onProductImpression({
          product: {
            ...data,
            titleRecFeed: title || formattedData?.dynTitle || '',
          },
          dataPosition: dataPosition?.toString(),
          platformSource: isMobile
            ? ENV_TYPE_DEVICE.ENV_TYPE_MOBILE
            : ENV_TYPE_DEVICE.ENV_TYPE_DESKTOP,
          typeVenderSource: formattedData?.typeOfRecFeed || '',
          segment: activeKey,
          recFeedRank: recFeedRank ? recFeedRank : 0,
          moduleTitle: title,
          promotionInternalName: internalPromotionName || '',
        });
      },

      onClickProduct: (data: ProductElementTracking) => {
        triggerSegmentTracking(
          {
            products: [{ ...data, titleRecFeed: title || formattedData?.dynTitle || '' }],
            vendorSource: formattedData?.typeOfRecFeed || '',
            listId: title || formattedData?.dynTitle || '',
            placementId: formattedData?.placement,
            modulePosition: dataPosition?.toString(),
            segment: activeKey,
            recFeedRank: recFeedRank ? recFeedRank : 0,
            moduleTitle: title,
            promotionInternalName: internalPromotionName || '',
          },
          SEGMENT_CLICK_EVENT,
          isMobile ? ENV_TYPE_DEVICE.ENV_TYPE_MOBILE : ENV_TYPE_DEVICE.ENV_TYPE_DESKTOP,
        );
      },
    }),
    [
      formattedData?.items,
      formattedData?.dynTitle,
      formattedData?.placement,
      formattedData?.typeOfRecFeed,
      onClickAddToWishListIcon,
      recFeedModulePosition,
      moduleTitle,
      openAddToBagModal,
      title,
      internalPromotionName,
      dataPosition,
      isMobile,
      activeKey,
      recFeedRank,
      post.type,
    ],
  );

  // this post type will be remove if data is empty
  return (
    <div
      className={clsx(className, { 'has-background': hasBackground })}
      style={styles}
      ref={recRef}
    >
      {/* Recommendation Feed  */}
      {!formattedData?.items?.length ? <SkeletonRecommendation /> : null}

      <div
        className={clsx('mb-6 tablet:mb-8 desktop:mb-12', {
          hidden: !formattedData?.items?.length,
        })}
      >
        <BaseRecommendation
          className={clsx({
            [ANIMATION_POST_TYPE_CONFIG.CUSTOM_STYLE_CLASS_NAME as string]: !!fontColor,
          })}
          title={title || formattedData?.dynTitle || ''}
          description={description || formattedData?.description || ''}
          ctaLabel={ctaLabel}
          ctaLink={ctaNavigationLink}
          feedBanner={formattedData?.feedBanner}
          productListOption={productListOption as ProductListOption}
          MediaLink={ProductLink}
          carouselOption={carouselOption}
          desktopMediaWrapper
          bleedType={bleedType}
        />
      </div>
    </div>
  );
};

export default memo(Recommendation);
