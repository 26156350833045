import Cookie, { getCookieDomain } from 'utils/cookie';
import { getNextWeek } from 'utils/tracking';

export const persistPromotionClickedPromotionName = (promotionName: string) => {
  if (!promotionName) {
    return;
  }
  /**
   * Since we re setting cookie on `zalora.[venture]`, it will be kept on `checkout.zalora.[venture]` as well
   */

  const cookie = new Cookie();

  cookie.set(
    'last_clicked_promotion_name',
    promotionName,
    getNextWeek(),
    getCookieDomain(window.location.hostname),
  );
};
