import { TextBanner as BaseTextBanner } from '@zalora/post-types';
import { TextBannerVariations } from '@zalora/post-types/lib/TextBanner/types';
import { BleedType, LoadingState } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useTwilioUserTrait } from 'hooks/api/useTwilioUserTrait';
import { toMilliseconds } from 'services/contentful/helper';
import { useLandingPageDispatch } from 'stores/landingPage';
import { RefinedTextBannerProps } from 'types/post-types/TextBannerTypes';
import { getDateTimePostType } from 'utils/post-type/helper';
import '@zalora/post-types/styles/text-promotion.css';

const TextPromotion: React.FC<RefinedTextBannerProps> = ({
  className,
  post,
  styles,
  activeKey = '',
}) => {
  const { removePostById } = useLandingPageDispatch();
  const {
    entryId = '',
    enableSegmentAudienceFilter,
    segmentAudience,
    audienceBucket,
    widthType,
  } = post || {};

  useTwilioUserTrait({
    enableSegmentAudienceFilter,
    segmentAudience,
    audienceBucket,
    callbackAction: () => removePostById(activeKey, entryId),
  });

  if (
    widthType !== TextBannerVariations.fullWidthSkinny &&
    widthType !== TextBannerVariations.halfWidthSkinny
  ) {
    return null;
  }

  return (
    <div
      className={clsx('mb-4 desktop:mb-6', className)}
      style={styles}
    >
      <BaseTextBanner
        internalName={post?.internalName || ''}
        widthType={
          (post?.widthType || TextBannerVariations.fullWidthSkinny) as TextBannerVariations
        }
        labelText={post?.labelText}
        labelTextColour={post?.labelTextColour}
        labelBackgroundColour={post?.labelBackgroundColour}
        navLink={post?.navLink}
        labelTextRight={post?.labelTextRight}
        labelTextColourRight={post?.labelTextColourRight}
        labelBackgroundColourRight={post?.labelBackgroundColourRight}
        navLinkRight={post?.navLinkRight}
        labelTextLeft={post?.labelTextLeft}
        labelTextColourLeft={post?.labelTextColourLeft}
        labelBackgroundColourLeft={post?.labelBackgroundColourLeft}
        navLinkLeft={post?.navLinkLeft}
        countdownTimerText={post?.countdownTimerText}
        countdownTimerStart={toMilliseconds(post?.countdownTimerStart)}
        countdownTimerEnd={toMilliseconds(post?.countdownTimerEnd)}
        getTime={getDateTimePostType}
        loadingState={LoadingState.Loaded}
        bleedType={post?.bleedType || BleedType.Half}
      />
    </div>
  );
};

export default memo(TextPromotion);
