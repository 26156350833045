import { RefObject, useEffect, useState } from 'react';
import { State, useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';
import useIntersectionObserver from './useIntersectionObserver';

type OnFetchedType<T> = (data: T) => void;

const useFetchInViewport = <T>(
  elementRef: RefObject<Element>,
  key: Nullable<string>,
  fetcher: () => Promise<T>,
  onFetched?: Nullable<OnFetchedType<T>>,
  shouldPersistData?: boolean,
) => {
  const { cache } = useSWRConfig();
  const { data } = (cache.get(key) as State<T>) || {};

  const [isViewed, setIsViewed] = useState(false);
  const entry = useIntersectionObserver(elementRef, {
    root: null,
    threshold: 0,
    rootMargin: '150px',
    freezeOnceVisible: true,
  });

  useEffect(() => {
    if (!isViewed && entry && entry.isIntersecting) {
      setIsViewed(true);
    }
  }, [entry, isViewed]);

  let isPersisted = isViewed;

  if (shouldPersistData) {
    isPersisted = isViewed && !data;
  }

  return useSWRImmutable(isPersisted ? key : null, fetcher, {
    onSuccess: (data) => {
      onFetched && onFetched(data);
    },
    fallbackData: data,
  });
};

export default useFetchInViewport;
