import React, { FC } from 'react';
import { RawGrid } from 'types/post-types/GridTypes';
import { getRatioPost } from 'utils/post-type/helper';
import { SkeletonItem } from './SkeletonItem';

interface Props {
  className?: string;
  post: RawGrid;
}

const GRID_WIDTH = 100;

export const SkeletonGrid: FC<Props> = ({ post }) => {
  if (!post) {
    return null;
  }

  const { columns = 0, rows = 0, title = '', entryId = '' } = post;
  const ratio = getRatioPost(post.media);

  const aspectRatio = ratio ? (ratio * GRID_WIDTH) / columns : GRID_WIDTH / columns;
  const hasTitle = !!title;

  return (
    <div>
      {hasTitle ? <SkeletonItem width="w-3/5 mt-1 pt-6 mb-4" /> : null}
      {[...Array(rows)].map((_, index) => {
        return (
          <div
            key={`row-${index}-${entryId}`}
            className={'mx-auto flex w-full justify-center gap-4 py-2 tablet:gap-8 desktop:gap-6'}
          >
            {[...Array(columns)].map((_, idx) => {
              return (
                <SkeletonItem
                  key={`column-${idx}-${entryId}`}
                  height="h-0"
                  style={{
                    paddingTop: `${aspectRatio}%`,
                  }}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
