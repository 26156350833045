import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { useLandingPageStore } from 'stores/landingPage';
import { selectEnablePersonalizeSegmentFlag } from 'stores/landingPage/selectors';
import { AudienceBucket } from 'types/post-types/PostTypes';
import { getAnonymousId } from 'utils/anonymous-id';
import { isVisibleToPersonalizedUser } from 'utils/post-type/helper';
import { getUserTrait } from 'utils/post-type/user-trait';
import useUser from './useUser';

interface Props {
  enableSegmentAudienceFilter?: boolean;
  segmentAudience?: string;
  audienceBucket?: AudienceBucket[];
  callbackAction?: () => void;
}

export const useTwilioUserTrait = (props?: Props) => {
  const enablePersonalizeSegmentFlag = useLandingPageStore(selectEnablePersonalizeSegmentFlag);
  const { data: user, isLoading: isUserLoading } = useUser();

  const zuid = user?.Zuid;
  const anonymousId = getAnonymousId();
  const userId = zuid || anonymousId;

  const isFetchSwr = userId && !isUserLoading && enablePersonalizeSegmentFlag;

  const { data: userTraitAudienceData, isLoading } = useSWR(
    isFetchSwr ? `twilio-segment-${userId}` : null,
    async () => await getUserTrait(userId, !!zuid),
    {
      dedupingInterval: 12 * 60 * 60 * 1000, // 12hours
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    },
  );

  useEffect(() => {
    if (!enablePersonalizeSegmentFlag || !props) {
      return;
    }

    const {
      enableSegmentAudienceFilter = false,
      segmentAudience,
      audienceBucket,
      callbackAction,
    } = props;
    const isUserTraitDefined = !isLoading;

    if (!isUserTraitDefined || !callbackAction || !enableSegmentAudienceFilter) {
      return;
    }

    const userData = { zuid, anonymousId };
    const postData = { segmentAudience, audienceBucket };

    //post is enabled personalize and not belong to certain user
    if (!isVisibleToPersonalizedUser(userData, postData, userTraitAudienceData)) {
      callbackAction();

      return;
    }
  }, [anonymousId, enablePersonalizeSegmentFlag, isLoading, props, userTraitAudienceData, zuid]);

  return useMemo(() => {
    return {
      data: userTraitAudienceData,
      isLoading: isUserLoading || isLoading,
      userId,
      enablePersonalizeSegmentFlag,
    };
  }, [enablePersonalizeSegmentFlag, isLoading, isUserLoading, userId, userTraitAudienceData]);
};
